import {
  GroupService,
  MemberService
} from "@/common/api.service";
import {
  GROUPS,
  SET_GROUPS,
  CREATE_GROUPS,
  UPDATE_GROUPS,
  REMOVE_GROUPS,
  //group forms
  GROUPFORMS,
  SET_GROUPFORMS,
  CREATE_GROUPFORMS,
  UPDATE_GROUPFORMS,
  REMOVE_GROUPFORMS,
  //form details
  GROUPFORMDETAILS,
  SET_GROUPFORMDETAILS,
  EXPORTCSV,
  IMPORTCSV,
  EXPORTCSVDEFAULT,
  IMPORTCSVDEFAULT
} from "./actions.type";
import { ISBUTTONLOADING } from "./mutations.type"; //ISLOADING, 

const state = {
  groups: {},
  groupsforms: {},
  groupFormDetail: {},
};

const getters = {
  groupformdetail(state) {
    return state.groupFormDetail;
  },
  groupsforms(state) {
    return state.groupsforms;
  },
  groups(state) {
    return state.groups;
  }
};

const actions = {
  async [CREATE_GROUPS](context, payload) {
    GroupService.init();
    GroupService.setHeader();
    context.commit(ISBUTTONLOADING, true);
    return new Promise(resolve => {
      GroupService.create(`organisations/${payload.organisation_id}/groups`, payload)
      .then(({ data }) => {
        context.dispatch(GROUPS);
        resolve(data);
        context.commit(ISBUTTONLOADING, false);
      })
      .catch(({ response }) => {
        resolve(response);
        context.commit(ISBUTTONLOADING, false);
      });
    });
  },
  async [UPDATE_GROUPS](context, payload) {
    GroupService.init();
    GroupService.setHeader();
    context.commit(ISBUTTONLOADING, true);
    return new Promise(resolve => {
      GroupService.patch(`organisations/${payload.organisation_id}/groups/${payload.uuid}`, payload)
      .then(({ data }) => {
        context.dispatch(GROUPS);
        resolve(data);
        context.commit(ISBUTTONLOADING, false);
      })
      .catch(({ response }) => {
        resolve(response);
        context.commit(ISBUTTONLOADING, false);
      });
    });
  },
  async [REMOVE_GROUPS](context, payload) {
    GroupService.init();
    GroupService.setHeader();
    return new Promise(resolve => {
      GroupService.destroy(`organisations/${payload.organisation_id}/groups/${payload.uuid}`)
      .then(({ data }) => {
        context.dispatch(GROUPS);
        resolve(data);
      })
      .catch(({ response }) => {
        resolve(response);
      });
    });
  },
  async [GROUPS](context,payload) {
    GroupService.init();
    GroupService.setHeader();
    //context.commit(ISLOADING, true);
    if(payload){
      const { data } = await GroupService.get(`organisations/${payload.organisation_id}/groups`, `${payload.filter}`);
      //context.commit(ISLOADING, false);
      context.commit(SET_GROUPS, data);
      return data;
    }
  },

  //groups forms actions
  async [GROUPFORMS](context,payload) {
    GroupService.init();
    GroupService.setHeader();
    //context.commit(ISLOADING, true);
    if(payload){
      const { data } = await GroupService.get(`groups/${payload}/forms`);
      //context.commit(ISLOADING, false);
      context.commit(SET_GROUPFORMS, data);
      return data;
    }
  },
  async [CREATE_GROUPFORMS](context, payload) {
    GroupService.init();
    GroupService.setHeader();
    context.commit(ISBUTTONLOADING, true);
    return new Promise(resolve => {
      GroupService.create(`groups/${payload.group_id}/forms`, payload)
      .then(({ data }) => {
        context.dispatch(GROUPFORMS);
        resolve(data);
        context.commit(ISBUTTONLOADING, false);
      })
      .catch(({ response }) => {
        resolve(response);
        context.commit(ISBUTTONLOADING, false);
      });
    });
  },
  async [UPDATE_GROUPFORMS](context, payload) {
    let data = {};
    data.title = payload.title;
    data.description = payload.description;
    data.fields = payload.fields;
    
    GroupService.init();
    GroupService.setHeader();
    context.commit(ISBUTTONLOADING, true);
    return new Promise(resolve => {
      GroupService.patch(`groups/${payload.group_id}/forms/${payload.uuid}`, data)
      .then(({ data }) => {
        context.dispatch(GROUPFORMS, payload.group_id);
        resolve(data);
        context.commit(ISBUTTONLOADING, false);
      })
      .catch(({ response }) => {
        resolve(response);
        context.commit(ISBUTTONLOADING, false);
      });
    });
  },
  async [REMOVE_GROUPFORMS](context, payload) {
    GroupService.init();
    GroupService.setHeader();
    return new Promise(resolve => {
      GroupService.destroy(`groups/${payload.group_id}/forms/${payload.uuid}`)
      .then(({ data }) => {
        context.dispatch(GROUPFORMS);
        resolve(data);
      })
      .catch(({ response }) => {
        resolve(response);
      });
    });
  },

  //get gorm detail
  async [GROUPFORMDETAILS](context, payload) {
    GroupService.init();
    GroupService.setHeader();
    const { data } = await GroupService.get(`forms/${payload.code}`);
    data.data.group_id = payload.group_id;
    context.commit(SET_GROUPFORMDETAILS, data.data);
    return data;
  },

  async [EXPORTCSV](context, payload) {
    GroupService.init();
    context.commit(ISBUTTONLOADING, true);
    const { data } = await GroupService.get(`forms/${payload}/csv-template`);
    const anchor = document.createElement('a');
    anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data);
    anchor.target = '_blank';
    anchor.download = 'sample-members.csv';
    anchor.click();
    context.commit(ISBUTTONLOADING, false);
  },

  async [EXPORTCSVDEFAULT](context, payload) {
    MemberService.init();
    context.commit(ISBUTTONLOADING, true);
    const { data } = await MemberService.get(`organisations/${payload}/members/csv-template`);
    const anchor = document.createElement('a');
    anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data);
    anchor.target = '_blank';
    anchor.download = 'sample-members.csv';
    anchor.click();
    context.commit(ISBUTTONLOADING, false);
  },

  async [IMPORTCSVDEFAULT](context, payload) {
    MemberService.init();
    MemberService.setHeader();
    var formData = new FormData();
    formData.append('csv_file', payload.csv_file);
    const { data } = await MemberService.create(`organisations/${payload.organisation_id}/members/import-csv`, formData);
    return data;
  },

  async [IMPORTCSV](context, payload) {
    context.commit(ISBUTTONLOADING, true);
    GroupService.init();
    GroupService.setHeader();
    var formData = new FormData();
    formData.append('csv_file', payload.csv_file);
    const { data } = await GroupService.create(`forms/${payload.code}/import-csv`, formData);
    context.commit(ISBUTTONLOADING, false);
    return data;
  },

  //Manage group fields

};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
  [SET_GROUPS](state, groups) {
    state.groups = groups;
  },
  [SET_GROUPFORMS](state, data) {
    state.groupsforms = data;
  },
  [SET_GROUPFORMDETAILS](state, data) {
    state.groupFormDetail = data;
  },
  ["FETCH_END"](state, { groups }) {
    state.events = groups;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
