import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/*",
  //   name: "page-404",
  //   component: () =>
  //     import("@/components/admin/notfound/MaintenanceComponent.vue"),
  //   meta: { title: "No Data Found", breadCrumb: "No Data Found" },
  // },
  // {
  //   path: "*",
  //   redirect: "/maintenance",
  // },
  //frontend routes
  
  {
    path: "/home",
    name: "home",
    component: () => import("@/components/frontend/home/HomeComponent.vue"),
    meta: { title: "Home Page", layout: "frontend" },
  },
  {
    path: "/terms-and-conditions",
    name: "terms-and-conditions",
    component: () =>
      import("@/components/frontend/content-pages/termsAndConditions.vue"),
    meta: { title: "Terms and Conditions", layout: "frontend" },
  },
  {
    path: "/cookie-policy",
    name: "cookie-policy",
    component: () =>
      import("@/components/frontend/content-pages/cookiePolicy.vue"),
    meta: { title: "Cookie Policy", layout: "frontend" },
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () =>
      import("@/components/frontend/content-pages/privacyPolicy.vue"),
    meta: { title: "Privacy Policy", layout: "frontend" },
  },
  {
    path: "/FAQs",
    name: "faqs",
    component: () =>
      import("@/components/frontend/content-pages/faqs.vue"),
    meta: { title: "FAQs", layout: "frontend" },
  },
  {
    path: "/acceptable-use-policy",
    name: "acceptable-use-policy",
    component: () =>
      import("@/components/frontend/content-pages/acceptableUsePolicy.vue"),
    meta: { title: "Acceptable Use Policy", layout: "frontend" },
  },
  {
    path: "/donations",
    name: "donations",
    component: () => import("@/components/frontend/campaign/DonateList.vue"),
    meta: { title: "Home Page", layout: "frontend" },
  },
  {
    path: "/donation-details/:donation_id",
    name: "donation-details",
    component: () =>
      import("@/components/frontend/campaign/DonationDetails.vue"),
    meta: { title: "Home Page", layout: "frontend" },
  },
  {
    path: "/campaign-donate/:campaign_id",
    name: "campaign-donate",
    component: () => import("@/components/frontend/campaign/DonationPage.vue"),
    meta: { title: "Donate", layout: "frontend" },
  },
  {
    path: "/ticket/orders",
    name: "orders",
    component: () => import("@/components/layouts/RouterView.vue"),
    meta: { title: "Orders", layout: "frontend" },
    children: [
      {
        path: "my-tickets",
        name: "my-tickets",
        component: () => import("@/components/frontend/order/Orderlist.vue"),
        meta: { title: "Ticket Orders", layout: "frontend" },
      },
      {
        path: "details/:event_uuid/:ticket_uuid",
        name: "ticket-orders-details",
        component: () => import("@/components/frontend/order/Order-detail.vue"),
        meta: { title: "Order details", layout: "frontend" },
      },
    ],
  },
  //product-checkout
  {
    path: "/product/cart/:uuid",
    name: "product-cart",
    component: () =>
      import("@/components/frontend/product/ProductCartComponent.vue"),
      meta: { title: "Product Cart", layout: "frontend" },
  },
  {
    path: "/product/checkout/:store_id",
    name: "product-checkout",
    component: () =>
      import("@/components/frontend/product/ProductCheckoutComponent.vue"),
      meta: { title: "Product Checkout", layout: "frontend" },
  },
  {
    path: "/product/my-orders",
    name: "my-orders",
    component: () =>
      import("@/components/frontend/product/ProductOrders.vue"),
      meta: { title: "Product Order", layout: "frontend" },
  },
  {
    path: "/product/orders-details/:order_uuid",
    name: "orders-details",
    component: () =>
      import("@/components/frontend/product/ProductOrderDetails.vue"),
      meta: { title: "Product Order Details", layout: "frontend" },
  },
  {
    path: "/product-payment/failure",
    name: "product-payment-failure",
    component: () =>
      import("@/components/frontend/product/ProductPaymentFailure.vue"),
      meta: { title: "Product Payment Failure", layout: "frontend" },
  },

  // event  frontend
  {
    path: "/event",
    component: () => import("@/components/layouts/RouterView.vue"),
    meta: { title: "Home Page", layout: "frontend" },
    children: [
      {
        path: "all",
        name: "view_all_events",
        component: () => import("@/components/frontend/home/all-events"),
        meta: { title: "Event List", layout: "frontend" },
      },
      {
        path: "event-detail/:uuid",
        name: "event_detail",
        component: () =>
          import(
            "@/components/frontend/event-details/EventDetailsComponent.vue"
          ),
        meta: { title: "Event Details", layout: "frontend" },
      },
      {
        path: "private/:share_code",
        name: "private_event_detail",
        component: () =>
          import(
            "@/components/frontend/event-details/EventDetailsComponent.vue"
          ),
        meta: { title: "Private Event Details", layout: "frontend" },
      },
      // {
      //   path: "event-booking/:event_uuid",
      //   name: "event-booking",
      //   component: () =>
      //     import("@/components/frontend/event-booking/Booking.vue"),
      //   meta: { title: "Event", layout: "frontend" },
      // },
      {
        path: "ticket-preview",
        component: () =>
          import("@/components/frontend/event-booking/PrintTicketView.vue"),
        meta: { title: "Payment", layout: "frontend" },
      },
      // {
      //   path: "ticket-summary/:event_uuid/:ticket_uuid",
      //   name: "ticket-summary",
      //   component:
      //     () => import("@/components/frontend/event-booking/TicketSummary.vue")
      //       ,
      //   meta: { title: "Event", layout: "frontend" },
      // },
      {
        path: "payment-transfer/:status",
        name: "payment-transfer",
        component: () =>
          import("@/components/frontend/event-booking/PaymentTransfer.vue"),
        meta: { title: "Event", layout: "frontend" },
      },
      {
        path: "ticket-booking/:event_uuid/:share_code?",
        name: "ticket-booking",
        component: () =>
          import("@/components/frontend/event-booking/TicketBooking.vue"),
        meta: { title: "Ticket Booking", layout: "frontend" },
      },
      {
        path: "share-ticket",
        name: "share-ticket",
        component: () => import("@/components/frontend/event-booking/TicketSharing.vue"),
        meta: { title: "Share Ticket", layout: "frontend" },
      },
    ],
  },
  // {
  //   path: "/make-payment",
  //   name: "make-payment",
  //   component: () => import("@/components/frontend/payment/MakePayment.vue"),
  //   meta: { title: "Make Payment", layout: "simple" },
  // },
  {
    path: "/launch-an-event",
    name: "launch-an-event",
    component: () =>
      import("@/components/frontend/launch-event/LaunchEventMainComponent.vue"),
    meta: { title: "Launch Event", layout: "frontend" },
  },
  {
    path: "/launch-a-campaign",
    name: "launch-a-campaign",
    component: () =>
      import(
        "@/components/frontend/launch-campaign/LaunchCampaignMainComponent.vue"
      ),
    meta: { title: "Launch Campaign", layout: "frontend" },
  },
  {
    path: "/checkout/:payment_for/:id",
    name: "checkout_page",
    component: () => import("@/components/frontend/campaign/Checkout.vue"),
    meta: { title: "Checkout", layout: "frontend" },
  },
  {
    path: "/campaign",
    component: () => import("@/components/layouts/RouterView.vue"),
    meta: { breadCrumb: "Campaign", title: "Campaign" },
    children: [
      {
        path: "all",
        name: "view_all_campaigns",
        component: () =>
          import("@/components/frontend/campaign/CampaignList.vue"),
        meta: { title: "Campaign", layout: "frontend" },
      },
      {
        path: "details/:campaign_id",
        name: "campaigns_detail",
        component: () =>
          import("@/components/frontend/campaign/CampaignDetails.vue"),
        meta: { title: "Campaign", layout: "frontend" },
      },
      // {
      //   path: "checkout/:campaign_id",
      //   name: "campaigns_checkout",
      //   component: () => import("@/components/frontend/campaign/Checkout.vue"),
      //   meta: { title: "Campaign", layout: "frontend" },
      // },
      {
        path: "payment-transfer/:status",
        name: "payment-transfer",
        component: () =>
          import("@/components/frontend/campaign/PaymentTransfer.vue"),
        meta: { title: "Campaign", layout: "frontend" },
      },
    ],
  },

  //blank layout *simple*
  {
    path: "/members/:code/:slug",
    name: "members-form",
    component: () => import("@/components/frontend/members/MembersForm.vue"),
    meta: { title: "Members Form", layout: "simple" },
  },

  {
    path: "/event-attendee",
    name: "event-attendee",
    component: () => import("@/components/frontend/event-attendee/attendee.vue"),
    meta: { title: "Attendee Form", layout: "simple" },
  },

  //Backend routes
  {
    path: "/terms-and-conditions",
    name: "term_condition",
    component: () =>
      import(
        "@/components/admin/cms_frontend/TermsConditionsFrontComponent.vue"
      ),
    meta: { title: "Terms & Conditions", layout: "auth-v2" },
  },
  {
    path: "/company-overview",
    name: "overview",
    component: () =>
      import(
        "@/components/admin/cms_frontend/CompanyOverviewFrontComponent.vue"
      ),
    meta: { title: "Company Overview", layout: "auth-v2" },
  },
  {
    path: "/crowdfunding",
    component: () => import("@/components/layouts/RouterView.vue"),
    name: "crowdfunding",
    meta: {
      breadCrumb: "Group Contributions",
      title: "Group Contributions",
      requiresAuth: true,
    },
    children: [
      {
        path: "list",
        name: "campaign-list",
        component: () =>
          import("@/components/admin/crowdfunding/CampaignList.vue"),
        meta: { breadCrumb: "Campaign List", title: "Campaign list" },
      },
      {
        path: "dashboard",
        name: "campaign-dashboard",
        component: () =>
          import("@/components/admin/crowdfunding/LandingComponent.vue"),
        meta: { breadCrumb: "Dashboard", title: "Campaign" },
      },
      {
        path: "campaign-type/:type",
        name: "campaign-type",
        component: () =>
          import("@/components/admin/crowdfunding/CampaignTypeComponent.vue"),
        meta: { breadCrumb: "Campaign Type", title: "Campaign Type" },
      },
      {
        path: "campaign-details/:type/:campaign_for/:campaign_id?",
        name: "campaign-details",
        component: () =>
          import(
            "@/components/admin/crowdfunding/CampaignDetailsComponent.vue"
          ),
        meta: { breadCrumb: "Details", title: "Details" },
      },
      {
        path: "location-categories/:type/:campaign_id",
        name: "location-categories",
        component: () =>
          import("@/components/admin/crowdfunding/LocationCategories.vue"),
        meta: { breadCrumb: "Location", title: "Location" },
      },
      {
        path: "campaign-faq/:type/:campaign_id",
        name: "campaign-faq",
        component: () =>
          import("@/components/admin/crowdfunding/FAQComponent.vue"),
        meta: { breadCrumb: "Faq", title: "Faq" },
      },
      {
        path: "campaign-event/:type/:campaign_id",
        name: "campaign-event",
        component: () =>
          import("@/components/admin/crowdfunding/CampaignEvent.vue"),
        meta: { breadCrumb: "Campaign", title: "Campaign" },
      },
      {
        path: "campaign-story/:type/:campaign_id",
        name: "campaign-story",
        component: () =>
          import("@/components/admin/crowdfunding/ProjectStory.vue"),
        meta: { breadCrumb: "Story", title: "Story" },
      },
      {
        path: "campaign-development/:type/:campaign_id",
        name: "campaign-development",
        component: () =>
          import("@/components/admin/crowdfunding/Development.vue"),
        meta: { breadCrumb: "Development", title: "development" },
      },
      {
        path: "primary-contact/:type/:campaign_id",
        name: "primary-contact",
        component: () =>
          import("@/components/admin/crowdfunding/PrimaryContact.vue"),
        meta: { breadCrumb: "primary-contact", title: "primary-contact" },
      },
      {
        path: "categories/:type/:campaign_id",
        name: "categories",
        component: () =>
          import("@/components/admin/crowdfunding/CategoriesComponent.vue"),
        meta: { breadCrumb: "Categories", title: "categories" },
      },
      {
        path: "donation-list/:campaign_id",
        name: "donation-list",
        component: () =>
          import("@/components/admin/crowdfunding/DonationsComponent.vue"),
        meta: { breadCrumb: "Donation List", title: "Donation List" },
      },
      {
        path: "donation-report",
        name: "donation-report",
        component: () =>
          import("@/components/admin/crowdfunding/DonationsReportComponent.vue"),
        meta: { breadCrumb: "Donation Report", title: "Donation Report" },
      },
    ],
  },
  {
    path: "/dashboard",
    component: () => import("@/components/admin/dashboard/DashboardComponent.vue"),
    name: "dashboard",
    meta: { breadCrumb: "Dashboard", title: "Dashboard", requiresAuth: true },
  },
  {
    path: "/members",
    component: () => import("@/components/layouts/RouterView.vue"),
    name: "members",
    meta: { breadCrumb: "Members", title: "Members", requiresAuth: true },
    children: [
      {
        path: "list",
        name: "members-list",
        component: () =>
          import("@/components/admin/members/MembersListComponent.vue"),
        meta: { breadCrumb: "Members List", title: "Members List" },
      },
    ],
  },
  {
    path: "/store",
    component: () => import("@/components/layouts/RouterView.vue"),
    name: "store",
    meta: { breadCrumb: "Store", title: "Store", requiresAuth: true },
    children: [
      {
        path: "list",
        name: "store-list",
        component: () =>
          import("@/components/admin/store/StoreListComponent.vue"),
        meta: { breadCrumb: "Store List", title: "Store List" },
      },
      {
        path: "products/:store_uuid",
        name: "product-list",
        component: () => import("@/components/admin/store/product/ProductListComponent.vue"),
        meta: { breadCrumb: "Product List", title: "Product List" },
      },
      {
        path: "product-order-report",
        name: "product-order-report",
        component: () => import("@/components/admin/store/ProductOrderReport.vue"),
        meta: { breadCrumb: "Product Order Report", title: "Product Order Report" },
      },
      {
        path: "product-report",
        name: "product-report",
        component: () => import("@/components/admin/store/ProductReport.vue"),
        meta: { breadCrumb: "Product Report", title: "Product Report" },
      },
    ]
  },
  //Events routes
  {
    path: "/event",
    component: () => import("@/components/layouts/RouterView.vue"),
    name: "event",
    meta: { breadCrumb: "Event", title: "Event", requiresAuth: true },
    children: [
      {
        path: "list",
        name: "event-list",
        component: () =>
          import("@/components/admin/event/EventListComponent.vue"),
        meta: { breadCrumb: "Event List", title: "Event List" },
      },
      {
        path: "purchase_ticket_report",
        name: "purchase-ticket-report",
        component: () =>
          import("@/components/admin/event/PurchaseTicketReport.vue"),
          meta: { breadCrumb: "Ticket Purchase Report", title: "Ticket Purchase Report" },
      },
      {
        path: "transaction_report",
        name: "transaction-report",
        component: () =>
          import("@/components/admin/event/TransactionReport.vue"),
          meta: { breadCrumb: "Transaction Report", title: "Transaction Report" },
      },
      {
        path: "report",
        name: "event-report",
        component: () =>
          import("@/components/admin/event/EventReportComponent.vue"),
          meta: { breadCrumb: "Event Report", title: "Event Report" },
      },
      {
        path: "upcoming-report",
        name: "upcoming-event-report",
        component: () =>
          import("@/components/admin/event/UpcomingEventReportComponent.vue"),
          meta: { breadCrumb: "Upcoming Event Report", title: "Upcoming Event Report" },
      },
      {
        path: "attendee_report",
        name: "attendee-report",
        component: () =>
          import("@/components/admin/event/AttendeeReportComponent.vue"),
          meta: { breadCrumb: "Attendee Report", title: "Attendee Report" },
      },
      {
        path: "event_recurring_passes",
        name: "reccuring-passes",
        component: () =>
          import("@/components/admin/event/EventRecurringPassesComponent.vue"),
          meta: { breadCrumb: "Recurring Passes", title: "Recurring Passes" },
      },
      {
        path: "detail/:event_uuid",
        name: "detail",
        component: () => import("@/components/admin/event/PreviewEvent.vue"),
        meta: { breadCrumb: "Event detail", title: "Event detail" },
      },
      {
        path: "event-details",
        name: "event-details",
        component: () =>
          import("@/components/admin/event/EventForm1Component.vue"),
        meta: { breadCrumb: "Add Event", title: "Add Event" },
      },
      {
        path: "event-location/:event_uuid",
        name: "event-location",
        component: () =>
          import("@/components/admin/event/EventLocationComponent.vue"),
        meta: { breadCrumb: "Event Locations", title: "Event Locations" },
      },
      {
        path: "event-online",
        component: () => import("@/components/admin/event/EventFormOnline.vue"),
        meta: { breadCrumb: "Online Event", title: "Online Event" },
      },
      {
        path: "event-series",
        component: () =>
          import("@/components/admin/event/EventSeriesComponent.vue"),
        meta: { breadCrumb: "Event Series", title: "Event Series" },
      },
      {
        path: "event-single",
        component: () => import("@/components/admin/event/SingleEventForm.vue"),
        meta: { breadCrumb: "Single Event", title: "Single Event" },
      },
      {
        path: "event-reccuring",
        component: () =>
          import("@/components/admin/event/ReccuringEventForm.vue"),
        meta: { breadCrumb: "Reccuring Event", title: "Reccuring Event" },
      },
      {
        path: "publish",
        component: () => import("@/components/admin/event/PublishEvent.vue"),
        name: "publish-event",
        meta: { breadCrumb: "Publish Event", title: "Publish Event" },
      },
      {
        path: "preview/:event_uuid",
        component: () => import("@/components/admin/event/PreviewEvent.vue"),
        name: "event-preview",
        meta: { breadCrumb: "Preview Event", title: "Preview Event" },
      },
    ],
  },
  //organisations routes
  {
    path: "/organisations",
    component: () => import("@/components/layouts/RouterView.vue"),
    meta: {
      breadCrumb: "Organisations",
      title: "Organisations",
      requiresAuth: true,
    },
    children: [
      {
        path: "list",
        component: () =>
          import("@/components/admin/organisations/OrganisationsComponent.vue"),
        meta: { breadCrumb: "Organisations List", title: "Organisations List" },
      },
    ],
  },
  //Groups routes
  {
    path: "/groups",
    component: () => import("@/components/layouts/RouterView.vue"),
    meta: { breadCrumb: "Groups", title: "Groups", requiresAuth: true },
    children: [
      {
        path: ":organisation_id",
        name: "groups",
        component: () =>
          import("@/components/admin/groups/GroupsComponent.vue"),
        meta: { breadCrumb: "Groups List", title: "Groups List" },
      },
      {
        path: "forms/:group_id",
        name: "froms",
        component: () =>
          import("@/components/admin/groups/GroupsFormsComponent.vue"),
        meta: { breadCrumb: "Forms List", title: "Forms List" },
      },
      {
        path: "configure-form/:group_id/:code",
        component: () =>
          import(
            "@/components/admin/members/ConfigureMembershipFormComponent.vue"
          ),
        name: "configure-form",
        meta: { breadCrumb: "Configure Form", title: "Configure Form" },
      },
    ],
  },
  //tickets routes
  {
    path: "/ticket",
    component: () => import("@/components/layouts/RouterView.vue"),
    name: "ticket",
    meta: { breadCrumb: "Ticket", title: "Ticket", requiresAuth: true },
    children: [
      {
        path: "ticket-list",
        name: "ticket-list",
        component: () =>
          import("@/components/admin/ticket/TicketListComponent.vue"),
        meta: { breadCrumb: "List", title: "List" },
      },
      {
        path: "event-ticket",
        name: "event-ticket",
        component: () =>
          import("@/components/admin/ticket/AddTicketComponent.vue"),
        meta: { breadCrumb: "Add Ticket", title: "Add Ticket" },
      },
      {
        path: "edit/event-ticket/:ticket_id?",
        name: "edit-event-ticket",
        component: () =>
          import("@/components/admin/ticket/TicketComponent.vue"),
        meta: { breadCrumb: "Ticket", title: "Ticket" },
      },
      {
        path: "promocode-list",
        name: "promocode-list",
        component: () =>
          import("@/components/admin/ticket/PromocodeListComponent.vue"),
        meta: { breadCrumb: "Promocode", title: "Promocode" },
      },
      {
        path: "ticket-paid",
        component: () => import("@/components/admin/ticket/TicketPayout.vue"),
        meta: { breadCrumb: "Ticket Pay", title: "Ticket Pay" },
      },
    ],
  },
  //cms routes
  {
    path: "/cms",
    component: () => import("@/components/layouts/RouterView.vue"),
    meta: { breadCrumb: "CMS", requiresAuth: true },
    children: [
      {
        path: "list",
        component: () =>
          import("@/components/admin/cms_backend/CmsListComponent.vue"),
        meta: { breadCrumb: "CMS List", title: "CMS List" },
      },
      {
        path: "add-page",
        component: () =>
          import("@/components/admin/cms_backend/AddPageComponent.vue"),
        meta: { breadCrumb: "Add Page", title: "Add Page" },
      },
    ],
  },
  //404 page route
  {
    path: "/page-404",
    name: "page-404",
    component: () =>
      import("@/components/admin/notfound/NotFoundComponent.vue"),
    meta: { title: "No Data Found", breadCrumb: "No Data Found" },
  },
  {
    path: "/maintenance",
    name: "page-404",
    component: () =>
      import("@/components/admin/notfound/MaintenanceComponent.vue"),
    meta: { title: "No Data Found", breadCrumb: "No Data Found" },
  },
  //bugreport route
  {
    path: "/report-bug",
    name: "report-bug",
    component: () =>
      import("@/components/admin/report_bug/ReportBugComponent.vue"),
    meta: {
      title: "Report Issue",
      breadCrumb: "Report Issue",
      requiresAuth: true,
    },
  },
  //Auth routes
  {
    path: "/authentication",
    redirect: "/login",
    component: () => import("@/components/layouts/RouterView.vue"),
    children: [
      {
        path: "login",
        component: () =>
          import("@/components/admin/authentication/LoginComponent.vue"),
        name: "login",
        meta: { title: "Login", layout: "auth" },
      },
      {
        path: "front-login",
        component: () =>
          import("@/components/admin/authentication/LoginComponent.vue"),
        name: "front-login",
        meta: { title: "Login", layout: "auth" },
      },
      {
        path: "register",
        component: () =>
          import("@/components/admin/authentication/RegisterComponent.vue"),
        name: "register",
        meta: { title: "Register", layout: "auth" },
      },
      {
        path: "page-reset-password/:token",
        component: () =>
          import(
            "@/components/admin/authentication/ResetPasswordComponent.vue"
          ),
        name: "resetpwd",
        meta: { title: "Reset Password", layout: "auth" },
      },
      {
        path: "verify-email",
        component: () =>
          import("@/components/admin/authentication/OtpComponent.vue"),
        name: "otp",
        meta: { title: "OTP", layout: "auth" },
      },
      {
        path: "forgot-password",
        component: () =>
          import(
            "@/components/admin/authentication/ForgotPasswordComponent.vue"
          ),
        name: "fpwd",
        meta: { title: "Forgot Password", layout: "auth" },
      },
    ],
  },

  //profile
  {
    path: "/setting",
    component: () => import("@/components/layouts/RouterView.vue"),
    name: "setting",
    meta: { breadCrumb: "Setting", title: "Setting", requiresAuth: true },
    children: [
      {
        path: "profile",
        component: () => import("@/components/admin/profile/EditProfile.vue"),
        meta: { breadCrumb: "Profile", title: "Profile" },
      },
      // {
      //   path: 'Profile',
      //   component:() => import("@/components/admin/setting/MyProfileComponent.vue"),
      //   meta: { breadCrumb: "My Profile", title: "Profile"}
      // },
      {
        path: "setup-profile",
        component: () =>
          import("@/components/admin/setting/SetupProfileComponent.vue"),
        meta: {
          breadCrumb: "Set Up Your Profile",
          title: "Set Up Your Profile",
        },
      },
    ],
  },
  {
    path: "/users",
    component: () => import("@/components/layouts/RouterView.vue"),
    name: "Users",
    meta: { breadCrumb: "Users", title: "Users", requiresAuth: true },
    children: [
      {
        path: "list",
        component: () => import("@/components/admin/user_management/UserListComponent.vue"),
        meta: { breadCrumb: "User-List", title: "User-List" },
      },
      {
        path: "role",
        component: () => import("@/components/admin/user_management/RoleListComponent.vue"),
        meta: { breadCrumb: "User-Role", title: "User-Role" },
      },
    ],
  },
  {
    path: "/payments",
    component: () => import("@/components/layouts/RouterView.vue"),
    name: "Payments",
    meta: { breadCrumb: "Payments", title: "Payments", requiresAuth: true },
    children: [
      {
        path: "bank-details",
        component: () => import("@/components/admin/payments/bank_details.vue"),
        meta: { breadCrumb: "Bank-Details", title: "Bank-Details" },
      },
      {
        path: "payout",
        name: "payments-payout",
        component: () => import("@/components/admin/payments/payout.vue"),
        meta: { breadCrumb: "Payout Option", title: "Payout Option" },
      },
    ],
  },

  //accounts
  {
    path: "/account",
    name: "admin-account",
    component: () => import("@/components/admin/account/AccountComponent.vue"),
    meta: { breadCrumb: "Accounts", title: "Accounts" },
  },
  //payment and transfer
  {
    path: "/pay-transfer",
    component: () => import("@/components/layouts/RouterView.vue"),
    meta: { breadCrumb: "Pay Or Transfer", title: "Pay Or Transfer" },
    children: [
      {
        path: "beneficiaries",
        component: () =>
          import(
            "@/components/admin/pay_to_transfer/BeneficiariesComponent.vue"
          ),
        meta: { breadCrumb: "Beneficiaries", title: "Beneficiaries" },
      },
      {
        path: "pay-transfer-details",
        component: () =>
          import("@/components/admin/pay_to_transfer/TransferComponent.vue"),
        meta: { breadCrumb: "Payments Details", title: "Payments" },
      },
      {
        path: "account-details",
        component: () =>
          import(
            "@/components/admin/pay_to_transfer/AccountDetailsComponent.vue"
          ),
        meta: { breadCrumb: "Account Details", title: "Account Details" },
      },
      {
        path: "user-account-details",
        component: () =>
          import(
            "@/components/admin/pay_to_transfer/UserAccountDComponent.vue"
          ),
        meta: {
          breadCrumb: "User Account Details",
          title: "User Account Details",
        },
      },
      {
        path: "exchange",
        component: () =>
          import("@/components/admin/pay_to_transfer/ExchangeComponent.vue"),
        meta: { breadCrumb: "Exchange", title: "Exchange" },
      },
      {
        path: "add-money",
        component: () =>
          import("@/components/admin/pay_to_transfer/AddMoneyComponent.vue"),
        meta: { breadCrumb: "Add Money", title: "Add Money" },
      },
    ],
  },
  //Invoice
  {
    path: "/invoice",
    component: () => import("@/components/layouts/RouterView.vue"),
    meta: { breadCrumb: "Invoice", title: "Invoice" },
    children: [
      {
        path: "list",
        component: () =>
          import("@/components/admin/invoice/InvoiceListComponent.vue"),
        meta: { breadCrumb: "Invoice List", title: "Invoice List" },
      },
      {
        path: "detail",
        component: () =>
          import("@/components/admin/invoice/InvoicePreviewComponent.vue"),
        meta: { breadCrumb: "Invoice Details", title: "Invoice Details" },
      },
      {
        path: "customer-list",
        component: () =>
          import("@/components/admin/invoice/AllCustomerComponent.vue"),
        meta: { breadCrumb: "All Customer", title: "All Customer" },
      },
      {
        path: "add-customer",
        component: () =>
          import("@/components/admin/invoice/AddCustomerComponent.vue"),
        meta: { breadCrumb: "Add Customer", title: "Add Customer" },
      },
      {
        path: "add-group",
        component: () =>
          import("@/components/admin/invoice/AddGroupComponent.vue"),
        meta: { breadCrumb: "Add Group", title: "Add Group" },
      },
      {
        path: "add-invoice-details",
        component: () =>
          import("@/components/admin/invoice/AddInvoiceInfoComponent.vue"),
        meta: { breadCrumb: "Invoice Details", title: "Invoice Details" },
      },
      {
        path: "add-invoice-item",
        component: () =>
          import("@/components/admin/invoice/AddInvoiceItemComponent.vue"),
        meta: { breadCrumb: "Add Invoice Items", title: "Add Invoice Items" },
      },
    ],
  },
  {
    path: "/c/:campaign_id",
    name: "campaign",
    component: () =>
      import("@/components/frontend/campaign/CampaignDetails.vue"),
    meta: { title: "Campaign", layout: "frontend" },
  },
  {
    path: "/e/:uuid/:share_code?",
    name: "event_detail_slug",
    component: () =>
      import(
        "@/components/frontend/event-details/EventDetailsComponent.vue"
      ),
    meta: { title: "Event Details", layout: "frontend" },
  },
  {
    path: "/:uuid",
    name: "event-detail",
    component: () =>
      import(
        "@/components/frontend/event-details/EventDetailsComponent.vue"
      ),
    meta: { title: "Event Details", layout: "frontend" },
  },
  {
    path: "*",  
    redirect: "/home",
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  base: "/",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = ":: Angel Communities :: " + to.meta.title;
  });
});

export default router;
