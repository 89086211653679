import {
  ReportBugServie
} from "@/common/api.service";
import {
  GET_BUG_CATEGORY,
  CREATE_REPORT_BUG,
} from "./actions.type";
import { SET_ERROR } from "./mutations.type";
const state = {
  errors: null,
  report_bug: {},
};

const getters = {
  report_bug(state) {
    return state.report_bug;
  }
};

const actions = {
  async [GET_BUG_CATEGORY]() {
    ReportBugServie.init();
    ReportBugServie.setHeader();
    const { data } = await ReportBugServie.get("bug-categories");
    return data;
  },

  async [CREATE_REPORT_BUG](context, payload) {
    ReportBugServie.init();
    ReportBugServie.setHeader();

    return new Promise(resolve => {
      ReportBugServie.create("events/report-bug", payload)
        .then(({ data }) => {
          context.commit(SET_ERROR, '');
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          resolve(response);
        });
    });
  },
};
const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
};
export default {
  state,
  getters,
  actions,
  mutations
};