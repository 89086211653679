import {
  OrganisationService,
} from "@/common/api.service";
import {
  ORGANISATIONS,
  SET_ORGANISATIONS,
  CREATE_ORGANISATIONS,
  UPDATE_ORGANISATIONS,
  REMOVE_ORGANISATIONS,
  GET_ORGANISATIONS_TYPE,
  SET_ORGANISATIONS_TYPE,
  SET_PRIMARY_ORGANISATIONS
} from "./actions.type";
import { ISBUTTONLOADING, SET_ERROR,PRIMARY_ORGANISATIONS } from "./mutations.type";

const state = {
  errors: null,
  organisations: [],
  organizationType: [],
  primaryOrganization: {},
};

const getters = {
  organisations(state) {
    return state.organisations;
  },
  organizationType(state) {
    return state.organizationType;
  },
  primaryOrganization(state) {
    return state.primaryOrganization;
  }
};

const actions = {

  async [GET_ORGANISATIONS_TYPE](context) {
    OrganisationService.init();
    OrganisationService.setHeader();
    // avoid extronuous network call if article exists
    OrganisationService.init();
    OrganisationService.setHeader();
    const { data } = await OrganisationService.get("organisation-types");
    context.commit(SET_ORGANISATIONS_TYPE, data.data);
    return data;
  },

  async [CREATE_ORGANISATIONS](context, payload) {
    OrganisationService.init();
    OrganisationService.setHeader();

    context.commit(ISBUTTONLOADING, true);
    return new Promise(resolve => {
      OrganisationService.create("organisations", payload)
      .then(({ data }) => {
        context.dispatch(ORGANISATIONS);
        resolve(data);
        context.commit(ISBUTTONLOADING, false);
      })
      .catch(({ response }) => {
        resolve(response);
        context.commit(ISBUTTONLOADING, false);
      });
    });
  },

  async [UPDATE_ORGANISATIONS](context, payload) {
    OrganisationService.init();
    OrganisationService.setHeader();
    return new Promise(resolve => {
      OrganisationService.update("organisations/"+payload.uuid, payload.formDataUpdate)
      .then(({ data }) => {
        context.dispatch(ORGANISATIONS);
        context.dispatch(SET_PRIMARY_ORGANISATIONS, data.data);
        resolve(data);
        context.commit(SET_ERROR, '');
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.errors);
        resolve(response);
      });
    });
  },
  
  async [REMOVE_ORGANISATIONS](context, payloadId) {
    OrganisationService.init();
    OrganisationService.setHeader();
    return new Promise(resolve => {
      OrganisationService.destroy("organisations/"+payloadId)
      .then(({ data }) => {
        context.commit(ORGANISATIONS);
        resolve(data);
      })
      .catch(({ response }) => {
        resolve(response);
      });
    });
  },

  async [ORGANISATIONS](context) {
    OrganisationService.init();
    OrganisationService.setHeader();
    const { data } = await OrganisationService.get("organisations");
    context.commit(SET_ORGANISATIONS, data);
    return data;
  },

  [SET_PRIMARY_ORGANISATIONS](context, data) {
    context.commit(PRIMARY_ORGANISATIONS, data);
  },
  

};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [PRIMARY_ORGANISATIONS](state, organization) {
    state.primaryOrganization = organization;
  },
  [SET_ORGANISATIONS_TYPE](state, types) {
    state.organizationType = types;
  },
  [SET_ORGANISATIONS](state, organisations) {
    state.organisations = organisations.data;
  },
  ["FETCH_END"](state, { organisations }) {
    console.log(organisations)
    state.events = organisations;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
