//User country actions type
export const COUNTRYLIST = "countryList";
export const SET_COUNTRY = "setCountry";
export const HIDE_ERROR = "hideError";
export const COUNTRYCODES = "countryCodes";


export const CHECK_PHONE = "checkPhone";
export const CHECK_EMAIL = "checkEmail";

export const GET_IP = "getClientIpAddress";

export const CONFIGURE_USER_SETTING = "configureUserSetting";
export const GET_CONFIGURE_USER_SETTING = "getConfigureUserSetting";

//User auth actions type
export const CHECK_AUTH = "checkAuth";
export const FETCH_PROFILE = "fetchProfile";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";
export const FORGOT_PASSWORD = "forgotPassword";
export const VERIFY_EMAIL_INFO = "verifyEmailInfo";
export const VERIFY_EMAIL = "verifyEmail";
export const VERIFY_EMAIL_RESEND = "verifyEmailResend";
export const RESET_PASSWORD = "resetPassword";

//ORGANISATIONS Actions type
export const GET_ORGANISATIONS_TYPE = "fetchOrganisationsTypes";
export const SET_ORGANISATIONS_TYPE = "setOrganisationsTypes";
export const ORGANISATIONS = "fetchOrganisations";
export const SET_ORGANISATIONS = "setOrganisations";
export const CREATE_ORGANISATIONS = "storeOrganisations";
export const UPDATE_ORGANISATIONS = "updateOrganisations";
export const REMOVE_ORGANISATIONS = "removeOrganisations";
export const SET_PRIMARY_ORGANISATIONS = "setSelectedOrganization";
export const GET_ALL_ORGANIZATION_LIST = "getAllOrganisationsList";



//groups Actions type
export const GROUPS = "fetchGroups";
export const SET_GROUPS = "setGroups";
export const CREATE_GROUPS = "storeGroups";
export const UPDATE_GROUPS = "updateGroups";
export const REMOVE_GROUPS = "removeGroups";

//groups forms Actions type
export const GROUPFORMS = "fetchGroupsForms";
export const SET_GROUPFORMS = "setGroupsForms";
export const CREATE_GROUPFORMS = "storeGroupsForms";
export const UPDATE_GROUPFORMS = "updateGroupsForms";
export const REMOVE_GROUPFORMS = "removeGroupsForms";
export const GROUPFORMDETAILS = "getGroupFormDetail";
export const SET_GROUPFORMDETAILS = "setGroupFormDetail";

//
export const EXPORTCSV = "exportCsv";
export const IMPORTCSV = "importCsv";
export const EXPORTCSVDEFAULT = "exportDefaultCsv";
export const IMPORTCSVDEFAULT = "importDefaultCsv";


//membership form action type
export const CREATE_FORM_RESPONSE = "setGroupFormDetail";
export const GET_MEMBERS_LIST = "getMemebersList";
export const MEMBER_STATISTICS = "getMemberStatistics";
export const EXPORT_MEMBERS_CSV = "exportCsv";
export const EXPORT_MEMBERS = "exportMembers";

export const GET_ORGANISATION_MEMBERS = "getOrganizationMembers";
export const GET_MEMBERS = "getMembers";
export const SET_ORGANISATIONS_MEMBERS = "setOrganizationMembers";
export const ADD_MEMBER = "addMember";
export const UPDATE_MEMBER = "updateMember";
export const UPDATE_MEMBER_DOB = "updateMemberDob";

//event actions
export const EVENT_DETAIL = "getEventDetail";
export const PUBLISHED_EVENT_DETAIL = "getPublishedEventDetail";
export const PRIVATE_EVENT_DETAIL = "getPrivateEventDetail";
export const FETCH_EVENT_REPORT = "fetchEventReport";
export const EXORT_EVENT_REPORT = "exportEventReport";
export const FETCH_EVENT = "fetchEvent";
export const FETCH_EVENT_BY_USER = "fetchEventByuser";
export const CREATE_EVENT = "createEvent";
export const UPDATE_EVENT = "updateEvent";
export const SET_CREATED_EVENT = "setcreateEvent";
export const GET_TYPE_CATEGORY = "getTypeCategory";
export const SET_TYPE_CATEGORY = "setTypeCategory";
export const ADD_EVENT_LOCATION = "addEventLocation";
export const EVENT_SCHEDULE = "eventSchedule";
export const PUBLISH_EVENT = "eventPublish";
export const SHARE_EVENT = "shareEvent";
export const SET_DEFAULT_CURRENCY = "setDefaultCurrency";
export const EVENT_LISTVIEW = "eventListView";
export const ORDERTRANSACTION = "orderTransaction";
export const DELETE_EVENT = "deleteEvent";
export const UNPUBLISH_EVENT = "unpublishEvent";
export const SAVE_EVENT = "saveEvent";
export const SEND_INVITE_EVENT = "sendInvoiteEvent";
export const RESEND_INVITE_EVENT = "resendInvoiteEvent";
export const POST_ATTENDEE_FORM = "storeAttendeeForm";
export const NOTIFY_ATTENDEE = "notifyAttendee";
export const EXPORT_DEFAULT_TICKET_CSV = "exportDefaultTicketCSV";
export const IMPORT_TICKET_CSV = "importTicketCSV";
export const DOWNLOAD_TICKET = "downloadTicket";
export const EVENT_REPORT_VIEW = "eventReportView";
export const EXPRESS_TICKET_DOWNLOAD = "expressTicketDownload";
export const EVENT_POSTPONE = "postponeEvent";
export const EXORT_TRANSACTION_REPORT = "exportTransactionReport";
export const GET_TRANSACTIONS = "fetchTransactions";
export const EVENT_RECURRING_PASSES = "getEventRecurringPasses";
export const EVENT_RECURRING_PASSES_DETAILS = "getEventRecurringPassesDetails";
export const UPCOMING_EVENT_REPORT = "upcomingEventsReport";
export const UPCOMING_EVENT_REPORT_EXPORT = "upcomingEventsReportExport";


//event activity log
export const CRETAE_ACTIVITY_LOG = "createactivitylog";

//Added actions for dietary info
export const ADD_DIETARY_OPTION = "addDietaryOption";
export const GET_DIETARY = "addDietary";
export const UPDATE_DIETARY = "updateDietary";
export const DELETE_DIETARY = "deleteDietary";
export const FETCH_ATTENDEE_REPORT = "fetchAttendeeReport";
export const EXORT_ATTENDEE_REPORT = "exportAttendeeReport";



//refund actions
export const VERIFY_REFUND_EMAIL = 'refundOTPVerification'
export const REFUND_AMOUNT = "eventAmountRefund";
export const PAYMENT_REFUND = "eventPaymentRefund";
export const REFUND_OTP = "refundOTP"

//event booking
export const EVENT_BOOKING = "eventBookingCheckout";
export const CHECK_VALIDATE_PROMOCODE = "checkValidatePromocode";
export const SET_TICKET_SUMMARY = "setTicketSummary";
export const GET_PURCHASED_TICKET = "getPurchasedTicket";


//Report Bug
export const GET_BUG_CATEGORY = "bugCategories";
export const CREATE_REPORT_BUG = "createReportBug";


//atendee forms
export const GET_ATTENDEE_FORM = "getAttendyForms";
export const CREATE_ATENDEE = "createAttendyForms";
export const UPDATE_ATENDEE = "updateAttendyForms";


//for tickets
export const CREATE_TICKET = "createTicket";
export const FETCH_TICKETS = "getTickets";
export const REMOVE_TICKET = "removeTicket";
export const EDIT_TICKET_DATA = "editTicketData";
export const UPDATE_TICKET = "updateTicket";
export const GET_ORDERS = "getOrders";
export const GET_ORDERS_DETAILS = "getOrdersDetails";
export const SET_PURCHASE_DETAILS = "setPurchaseDetail";
export const PURCHASE_SOLDOUT = "setSoldout";
export const RESEND_TICKET = "resendTicket"
export const TICKET_CURRENCY = "getTicketCurrency"
export const TICKET_SHORT = "storeTicketShort"
export const TOGGLE_IS_HIDDEN = "toggleIsHidden"


//for promocode
export const CREATE_PROMOCODE = "createPromocode";
export const FETCH_PROMOCODE = "getPromocode";
export const REMOVE_PROMOCODE = "removePromocode";
export const EDIT_PROMOCODE = "editPromocode";
export const UPDATE_PROMOCODE = "updatePromocode";


//for promocode
export const FETCH_ORGNAME = "getOrganizationName";
export const CREATE_CAMPAIGN = "createCampaing";
export const FETCH_CAMPAIGN = "fetchCampaing";
export const FETCH_CAMPAIGNS = "fetchCampaings";
export const CREATE_CAMPAIGN_LOCATION = "createCampaignLocation";
export const ADD_FAQ = "addFaq";
export const ADD_STORY = "addProjectStory";
export const ADD_PRODUCT_STAGE = "addProductStage";
export const DOCUMENT_UPLOAD = "addDocumentUpload";
export const FETCH_DEVELOPMENT_DOCUMENT = "fetchDevelopmentDocument";
export const ADD_PRIMARY_CONTACT = "addPrimaryContact";
export const FETCH_CAMPAIGN_CONTACT = "fetchPrimaryContact";
export const UPDATE_PRIMARY_CONTACT = "updatePrimaryContact";
export const ADD_TEAM_MEMBER = "addTeamMember";
export const FETCH_TEAMMEMBERS = "fetchTeamMember";
export const UPDATE_TEAM_MEMBER = "updateTeamMember";
export const FETCH_CAMPAIGN_DETAILS = "fetchCampaignDetails";
export const UPDATE_CAMPAIGN = "updateCampaign";
export const UPDATE_CAMPAIGN_LOCATION = "updateCampaignLocation";
export const UPDATE_FAQ = "updateFaq";
export const DETELE_FAQ = "deleteFaq";
export const UPDATE_STORY = "updateStory";
export const UPDATE_PRODUCT_STAGE = "updateProductStage";
export const REMOVE_DOCUMENT = "removeDocument";
export const ADD_MATRIX_STAGE = "addMatrixStage";
export const DELETE_MATRIX = "deleteMatrix";
export const CAMPAIGN_DONATE = "campaignDonate";
export const GET_DONATIONS = "getCampaignDonations";
export const GET_DONATE_DETAILS = "getCampaignDonationsDetails";
export const ADD_CAMPAIGN_CATEGORY = "addCampaignCategory";
export const GET_CAMPAIGN_CATEGORY = "getCampaignCategory";
export const UPDATE_CAMPAIGN_CATEGORY = "updateCampaignCategory";
export const DONATE_DETAILS = "donateDetails";
export const COMBINELOADER = "loaderCombine";
export const CAMPAIGN_ORDERTRANSACTION = "campaignDonationTransaction";
export const CAMPAIGN_COMMON_ACTION = "campaignCommonAction";
export const NOTIFYBYEMAIL = "notifyOrderByEmail";
export const PAYMENTCREATE = "PaymentCreate";
export const GET_PERTICULAR_CAMPAIGN_DONATION = "getDonationByCampaign";
export const DELETE_CAMPAIGN = "deleteCampaign";
export const DONATION_REPORT = "donationReport";

export const SEND_EVENT_ALERT_TO_ATTENDEE = "sendNotificationToAttendee";
export const GET_NORIFICATION_LOGS = "getNotificationLogs";


//Payangel authentication for checkout
export const PAYANGEL_LOGIN = "payangelLogin";

//payment/transactions 

export const GET_TRANSACTION_DETAILS = "getTransactionDetails";

//Store admin
export const GET_STORE_LIST = "getStoreList";
export const ADD_STORE = "addStore";
export const UPDATE_STORE = "updateStore";
export const DELETE_STORE = "deleteStore";
export const GET_PRODUCT_LIST = "getProductList";
export const ADD_PRODUCT = "addProduct";
export const PRODUCT_DETAILS = "productDetails";
export const UPDATE_PRODUCT = "updateProduct";
export const DELETE_PRODUCT = "deleteProduct";
export const DELETE_PRODUCT_VARIATION = "deleteProductVariation";
export const PRODUCT_ORDER_REPORT = "productOrderReport";
export const PRODUCT_REPORT = "productReport";
export const MY_PRODUCT_ORDERS = "myProductOrders";
export const MY_ORDERS_DETAIL = "myOrdersDetail";

export const PRODUCT_CHECKOUT = "productCheckout";


//PRODUCT_PURCHASE front
export const PRODUCT_PURCHASE = "productPurchase";

// Payouts backend
export const ADD_BANKACCOUNT = "addBankAccount";
export const GET_BANKACCOUNT_LIST = "getBankAccountList";
export const UPDATE_BANKACCOUNT = "updateBankAccount";
export const CREATE_PAYOUT_REQUEST = "createPayoutRequest";
export const GET_PAYOUT_REQUEST_LIST = "getPayoutRequestList";
export const CHANGE_PAYOUTREQUEST_STATUS = "changePayoutRequestStatus";
export const EXPORT_PAYOUT_REQUEST_REPORT = "exportPayoutRequestReport";
export const UPDATE_PAYOUT_REQUEST = "updatePayoutRequestToApprove";

//User Management auth actions type
export const CREATE_USER = "createUser";
export const USER_ROLES = "userRole";
export const ADD_ROLE = "addUserRole";
export const ROLE_PERMISSIONS = "rolePermissions";
export const ASSIGN_PERMISSION_TO_ROLE = "assignPermissionToRole";
export const USER_LIST = "getUserList";
export const UPDATE_USER_ROLE = "updateUserRole";
export const RECENT_USER_LIST = "getRecentJoinedUser";


export const CHECK_PHONEVALID = "checkIsPhoneValid";
