
import {
  MemberService
} from "@/common/api.service";
import {
  CREATE_FORM_RESPONSE,
  GET_MEMBERS_LIST,
  //EXPORT_MEMBERS_CSV,
  EXPORT_MEMBERS,
  GET_ORGANISATION_MEMBERS,
  SET_ORGANISATIONS_MEMBERS,
  GET_MEMBERS,
  MEMBER_STATISTICS,
  UPDATE_MEMBER_DOB,
  UPDATE_MEMBER
} from "./actions.type";
import { SET_ERROR, ISLOADING } from "./mutations.type";

const state = {
  errors: null,
  organisationMembers: []
};

const getters = {
  organisationMembers(state) {
    return state.organisationMembers;
  }
};

const actions = {
  async [CREATE_FORM_RESPONSE](context, payload) {
    MemberService.init();
    return new Promise(resolve => {
      MemberService.create(`forms/${payload.code}/responses`, payload.field)
        .then(({ data }) => {
          context.commit(SET_ERROR, '');
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  async [GET_MEMBERS_LIST](context, payload) {
    MemberService.init();
    MemberService.setHeader();
    return new Promise(resolve => {
      MemberService.create(`groups/${payload.groupId}/members`, payload.field)
        .then(({ data }) => {
          context.commit(SET_ERROR, '');
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  // async [EXPORT_MEMBERS_CSV](context, payload) {
  //   console.log("EXPORT_MEMBERS_CSV >> ",payload);
  //   MemberService.init();
  //   MemberService.setHeader();
  //   return new Promise(resolve => {
  //     MemberService.create( `forms/${payload.code}/export-csv`, payload.field)
  //     .then(({ data }) => {
  //       context.commit(SET_ERROR, '');
  //       resolve(data);
  //     })
  //     .catch(({ response }) => {
  //       context.commit(SET_ERROR, response.data.errors);
  //     });
  //   });
  // },
  async [GET_ORGANISATION_MEMBERS](context, payload) {
    MemberService.init();
    MemberService.setHeader();
    context.commit(ISLOADING, true);
    const { data } = await MemberService.get(`organisations/${payload.organisation_id}/members`, `${payload.filter}`);
    context.commit(SET_ORGANISATIONS_MEMBERS, data.data);
    context.commit(ISLOADING, false);
    return data;
  },

  async [MEMBER_STATISTICS](context, payload) {
    MemberService.init();
    MemberService.setHeader();
    const { data } = await MemberService.get(`organisations/${payload}/members/statistics`);
    return data;
  },

  async [GET_MEMBERS](context, payload) {
    MemberService.init();
    MemberService.setHeader();
    //context.commit(ISLOADING, true);
    const { data } = await MemberService.get(`members`, `?organisation_id=${payload.organisation_id}`);
    //context.commit(ISLOADING, false);
    return data;
  },

  async [UPDATE_MEMBER](context, payload) {
    MemberService.init();
    MemberService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      MemberService.patch(`/organisations/memberdata/${payload.member_uuid}/update`, payload.params)
        .then(({ data }) => {
          context.commit(ISLOADING, false);
          context.commit(SET_ERROR, '');
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(ISLOADING, false);
          resolve(response);
        });
    });
  },
  // member dob update
  async [UPDATE_MEMBER_DOB](context, payload) {
    MemberService.init();
    MemberService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      MemberService.patch(`/organisations/memberdata/${payload.member_uuid}/updateDob`, payload.params)
        .then(( data ) => {
          context.commit(ISLOADING, false);
          context.commit(SET_ERROR, '');
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(ISLOADING, false);
          resolve(response);
        });
    });
  },
  // members downloadCsv
  async [EXPORT_MEMBERS](context, payload) {
    MemberService.setHeader();
    MemberService.downloadCsv(`organisations/${payload.organisation_id}/members/downloadCsv${payload.filter}`)
  },

};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_ORGANISATIONS_MEMBERS](state, members) {
    state.organisationMembers = members;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
