export const CONFIGURTHEME = "configureTheme";
export const FETCH_START = "setLoading";
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";
export const SET_PROFILE = "setProfile";
export const UPDATE_AUTH_USER = "updateProfile";
export const PRIMARY_ORGANISATIONS = "selectedOrganization";
export const ISLOADING = "isLoading";
export const ISBUTTONLOADING = "isButtonLoading";
export const SET_FETCH_EVENT = "setFetchEvent";
export const SET_FETCH_EVENT_REPORT = "setFetchEventReport"
export const SET_FETCH_EVENT_BY_USER = "setFetchEventByUser";
export const MERGE_EVENTS = "mergeEvent";
export const SET_CREATED_EVENT = "setRecentCreatedEvent";
export const SET_FETCH_TICKETS = "setFetchTickets";
export const EDIT_TICKET = "editTicket";
export const EVENT_DETAIL = "eventDetail";
export const MERGE_EVENTS_ALL = "mergeEventAll";
export const MERGE_EVENT_REPORTS_ALL = "mergeEventReportAll"
export const SET_EVENT_DETAIL = "setEventDetail";
export const TICKET_SUMMARY = "ticketSummary";
export const PURCHASE_DETAILS = "purchaseDetail";
export const SOLD_OUT = "sold_out";
export const SET_CAMPAIGN_DETAILS = "setCampaignDetails";
export const ISDONATE = "isDonate";
export const ISLOADERCOMBINE = "isLoaderCombine";
export const SET_EMAIL_VERIFY_TOKEN = "setEmailVerifyToken";
export const ISSIDEBARMINI = "sidebarMini";
export const SET_PHONE_ERROR = "setPhoneError";
export const SET_EMAIL_ERROR = "setEmailError";

export const ADD_TO_CART = "addProductToCart";
export const PRODUCT_PURCHASE_DETAILS = "productPurchaseDetail";
