import { StoreService } from "@/common/api.service";  
import { 
    PRODUCT_PURCHASE,
    GET_STORE_LIST,
    ADD_STORE,
    UPDATE_STORE,
    DELETE_STORE,
    GET_PRODUCT_LIST,
    ADD_PRODUCT,
    PRODUCT_DETAILS,
    UPDATE_PRODUCT,
    DELETE_PRODUCT,
    DELETE_PRODUCT_VARIATION,
    PRODUCT_CHECKOUT,
    PRODUCT_ORDER_REPORT,
    PRODUCT_REPORT,
    MY_PRODUCT_ORDERS,
    MY_ORDERS_DETAIL
 } from "./actions.type";
import { 
    ADD_TO_CART ,
    ISLOADING, 
    SET_ERROR,
    PRODUCT_PURCHASE_DETAILS
} from "./mutations.type";
  
const state = {
    cartitems: [],
    product_details:[],
};

const getters = {
    cartitems(state) {
        return state.cartitems;
    },
    product_details(state) {
        return state.product_details;
    },
};

const actions = {
    async [GET_STORE_LIST](context, payload) {
        StoreService.init();
        StoreService.setHeader();
        const data  = await StoreService.get(`stores/${payload.organisation_id}`);
        return data;
    },
    async [ADD_STORE](context, payload) {
        StoreService.init();
        StoreService.setHeader();
        context.commit(ISLOADING, true);
        return new Promise(resolve => {
          StoreService.create(`store/${payload.organisation_id}`, payload.data)
          .then((data) => {
            context.commit(ISLOADING, false);
            context.commit(SET_ERROR, '');
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(ISLOADING, false);
            context.commit(SET_ERROR, response.data.errors);
            resolve(response);
          });
        });
    },
    async [UPDATE_STORE](context,payload) {
        StoreService.init();
        context.commit(ISLOADING, true);
        return new Promise(resolve => {
          StoreService.patch(`${payload.store_id}/store`, payload.data)
          .then(( data ) => {
            context.commit(ISLOADING, false);
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(ISLOADING, false);
            context.commit(SET_ERROR, response.data.errors);
            resolve(response);
          });
        });
    },
    async [DELETE_STORE](context,payload) {
        StoreService.init();
        context.commit(ISLOADING, true);
        return new Promise(resolve => {
          StoreService.destroy(`${payload}/store`)
          .then((data) => {
            context.commit(ISLOADING, false);
            resolve(data);
          })
          .catch(({response}) => {
            context.commit(ISLOADING, false);
            resolve(response);
          });
        });
    },
    // Products
    async [GET_PRODUCT_LIST](context, payload) {
        StoreService.init();
        StoreService.setHeader();
        const data  = await StoreService.get(`${payload}/stores/products`);
        context.commit(SET_ERROR, '');
        return data;
    },
    async [PRODUCT_DETAILS](context, payload) {
      StoreService.init();
      StoreService.setHeader();
      const data  = await StoreService.get(`store/product/${payload}`);
      context.commit(SET_ERROR, '');
      return data;
    },
    async [ADD_PRODUCT](context, payload) {
        StoreService.init();
        StoreService.setHeader();
        context.commit(ISLOADING, true);
        return new Promise(resolve => {
          StoreService.create(`${payload.store_id}/store/product`, payload.data)
          .then((data) => {
            context.commit(ISLOADING, false);
            context.commit(SET_ERROR, '');
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(ISLOADING, false);
            context.commit(SET_ERROR, response.data.errors);
            resolve(response);
          });
        });
    },
    async [UPDATE_PRODUCT](context,payload) {
        StoreService.init();
        context.commit(ISLOADING, true);
        return new Promise(resolve => {
          StoreService.post(`store/${payload.product_id}/product`, payload.data)
          .then(( data ) => {
            context.commit(ISLOADING, false);
            context.commit(SET_ERROR, '');
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(ISLOADING, false);
            context.commit(SET_ERROR, response.data.errors);
            resolve(response);
          });
        });
    },
    async [DELETE_PRODUCT](context,payload) {
      StoreService.init();
      context.commit(ISLOADING, true);
      return new Promise(resolve => {
        StoreService.destroy(`store/${payload}/product`)
        .then((data) => {
          context.commit(ISLOADING, false);
          resolve(data);
        })
        .catch(({response}) => {
          context.commit(ISLOADING, false);
          resolve(response);
        });
      });
    },
    async [DELETE_PRODUCT_VARIATION](context,payload) {
      StoreService.init();
      context.commit(ISLOADING, true);
      return new Promise(resolve => {
        StoreService.destroy(`store/product/${payload}/product-variation`)
        .then((data) => {
          context.commit(ISLOADING, false);
          resolve(data);
        })
        .catch(({response}) => {
          context.commit(ISLOADING, false);
          resolve(response);
        });
      });
    },
    // Products order Report
    async [PRODUCT_ORDER_REPORT](context,payload) {
      StoreService.init();
      StoreService.setHeader();
      context.commit(ISLOADING, true);
      var query = ''
      if(payload){
        query = `?perPage=20`;
        if (payload.store_id) {
          query = query + '&storeId=' + payload.store_id;
        }
        query = query + `&page=${payload.page==undefined ? 1 : payload.page}`;
        if (payload.status) {
          query = query + `&status=${payload.status && payload.status !== null ? payload.status : null}`;
        }
      }
      const { data } = await StoreService.get(`product-order-report${query}`);
      context.commit(ISLOADING, false);
      return data;
    },
    
    async [PRODUCT_REPORT](context,payload) {
      StoreService.init();
      StoreService.setHeader();
      context.commit(ISLOADING, true);
      var query = ''
      if(payload){
        query = `?perPage=20`;
        if (payload.store_id) {
          query = query + '&storeId=' + payload.store_id;
        }
        if (payload.organization_id) {
          query = query + '&organisationId=' + payload.organization_id;
        }
        query = query + `&page=${payload.page==undefined ? 1 : payload.page}`;
        if (payload.status) {
          query = query + `&status=${payload.status && payload.status !== null ? payload.status : null}`;
        }
      }
      const { data } = await StoreService.get(`product-report${query}`);
      context.commit(ISLOADING, false);
      return data;
    },

    // MY PRODUCT ORDERS list
    async [MY_PRODUCT_ORDERS](context) {
      StoreService.init();
      StoreService.setHeader();
      const data  = await StoreService.get(`user/orders`);
      context.commit(SET_ERROR, '');
      return data;
    },
    async [MY_ORDERS_DETAIL](context, payload) {
      StoreService.init();
      StoreService.setHeader();
      const data  = await StoreService.get(`user/${payload}/order`);
      context.commit(SET_ERROR, '');
      return data;
    },

    [PRODUCT_PURCHASE](context, payload) {
        context.commit(PRODUCT_PURCHASE_DETAILS, payload);
    },

    async [PRODUCT_CHECKOUT](context,payload) {
      StoreService.init();
      context.commit(ISLOADING, true);
      return new Promise(resolve => {
        StoreService.post(`shop/checkout`, payload)
        .then(( data ) => {
          context.commit(ISLOADING, false);
          context.commit(SET_ERROR, '');
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(ISLOADING, false);
          context.commit(SET_ERROR, response.data.errors);
          resolve(response);
        });
      });
    },
};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
    [ADD_TO_CART](state, product) {
        if(state.cartitems.length > 0){
            let itemFound = 0;
            if(product.color){
                itemFound = state.cartitems.findIndex((cart) => cart.id == product.id && cart.size == product.size && cart.color == product.color );
            }else{
                itemFound = state.cartitems.findIndex((cart) => cart.id == product.id && cart.size == product.size );
            }

            if(itemFound == -1){
                state.cartitems.push(product);
            }else{
                state.cartitems[itemFound].price = state.cartitems[itemFound].price + product.price;
                state.cartitems[itemFound].quantity = state.cartitems[itemFound].quantity + product.quantity; 
            }
        }else{
            state.cartitems.push(product);
        }
        state.cartitems.map((data) => {
            data.priceAfterDiscount = 0;
            if(data.isDiscountable) {
                if(data.idscountType == "percentage"){
                    data.priceAfterDiscount = data.price - (data.price*data.discount/100);
                }
                if(data.idscountType == "amount"){
                    data.priceAfterDiscount = data.price - data.discount;
                }
            }else{
                data.priceAfterDiscount = data.price
            }
        });
    },
    [PRODUCT_PURCHASE_DETAILS](state, data) {
        state.product_details = data;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};