import Vue from "vue";
import App from "./App.vue";
//import VueRouter from "vue-router";
import store from "./store";
import router from "./routes";
import axios from "axios";
import VueAxios from "vue-axios";

import ApiService from "./common/api.service";
import { CHECK_AUTH, LOGOUT } from "./store/actions.type";

import "../src/plugins/datetimefilter";

import Vue2Filters from "vue2-filters";

Vue.prototype.$isMobile = function() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Check for Android
  if (/android/i.test(userAgent)) {
    return true;
  }

  // Check for iOS
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return true;
  }

  // Check for other mobile user agents
  if (/Mobi|Android/i.test(userAgent)) {
    return true;
  }

  return false;
};

Vue.use(Vue2Filters);
Vue.filter("error", function (val) {
  return val;
});

var numeral = require("numeral");
Vue.filter("formatNumber", function (value) {
  return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
});

//manage json token
Vue.prototype.$http = axios;
const token = localStorage.getItem("token");
if (token) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] = token;
}

Vue.prototype.$setNoImageSrc = function (x) {
  x.target.src = require("@/assets/images/events--no-img.png");
};
Vue.prototype.$setNoImageSrcEventCover = function (x) {
  x.target.src = require("@/assets/images/campaign--no-img.png");
};

//Payangel Auth Pages version-new

//layouts file
Vue.component("simple-layout", () =>
  import("@/components/layouts/simple/SimpleLayout.vue")
);
Vue.component("auth-layout", () =>
  import("@/components/layouts/auth/AuthLayout.vue")
);
Vue.component("default-layout", () =>
  import("@/components/layouts/default/DefaultLayout.vue")
);
Vue.component("frontend-layout", () =>
  import("@/components/layouts/frontend/FrontendLayout.vue")
);

import "./vee-validate";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";

Vue.use(VueToast);
Vue.use(VueAxios, axios);

//BootstrapVue
import { BootstrapVue } from "bootstrap-vue";
Vue.use(BootstrapVue);

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

//Fragment
import Fragment from "vue-fragment";
Vue.use(Fragment.Plugin);

import VueMeta from "vue-meta";
Vue.use(VueMeta);

Vue.config.productionTip = false;
ApiService.init();

window.popStateDetected = false;
window.addEventListener("popstate", () => {
  window.popStateDetected = true;
});

router.beforeEach((to, from, next) => {
  // const IsItABackButton = window.popStateDetected;
  // const backPath = window.location.pathname;

  if (to.name == "event-list") {
    if (window.sessionStorage.getItem("eventId")) {
      window.sessionStorage.removeItem("eventId");
    }
  }

  let is_verified = window.sessionStorage.getItem("is_verified");
  let isAuthenticated = window.sessionStorage.getItem("id_token")
    ? true
    : false;

  // if ( IsItABackButton && backPath == "/authentication/verify-email" && is_verified === "true") {
  //   next({ name: "members-list" });
  // }

  //console.log("to >> >> ",to);
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    //store.getters.isAuthenticated && is_verified === "false"
    // if((to.name === 'login' || to.name === 'register' || to.name === 'resetpwd' || to.name === 'otp' || to.name === 'fpwd' ) && is_verified === "true"){
    //   next({ name: "members-list" });
    // }
    if (!isAuthenticated) {
      next({ name: "login" });
    } else if (isAuthenticated && is_verified === "false") {
      next({ name: "otp" });
    } else {
      Promise.all([store.dispatch(CHECK_AUTH)]).then(next());
    }
    //window.scrollTo(0, 0);
  } else {
    if (to.name == "otp" && is_verified == null) {
      next({ name: "login" });
    }
    if (to.name != "otp" && isAuthenticated && is_verified === "false") {
      store.dispatch(LOGOUT);
      next();
    }
    if (
      (to.name === "login" ||
        to.name === "register" ||
        to.name === "resetpwd" ||
        to.name === "otp" ||
        to.name === "fpwd") &&
      is_verified === "true"
    ) {
      next({ name: "members-list" });
    } else {
      next();
    }
    //window.scrollTo(0, 0);
  }
});
import mixin from "./mixins/helperMixins";
Vue.mixin(mixin);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
