import { PaymentServie, PayoutService } from "@/common/api.service";
import { 
  GET_TRANSACTION_DETAILS,
  ADD_BANKACCOUNT,
  UPDATE_BANKACCOUNT,
  GET_BANKACCOUNT_LIST,
  CREATE_PAYOUT_REQUEST,
  CHANGE_PAYOUTREQUEST_STATUS,
  GET_PAYOUT_REQUEST_LIST,
  EXPORT_PAYOUT_REQUEST_REPORT
 } from "./actions.type";
 import {
  ISLOADING, 
  SET_ERROR,
} from "./mutations.type";

const state = {
};

const getters = {
  
};

const actions = {

  async [GET_TRANSACTION_DETAILS](context, transaction_id) {
    PaymentServie.initStage();
    const { data } = await PaymentServie.get(`payment/details/${transaction_id}`);
    return data;
  },

  async [ADD_BANKACCOUNT](context, payload) {
    PayoutService.init();
    PayoutService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      PayoutService.create(`user/bank-account`,payload)
      .then((data) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.errors);
        resolve(response);
      });
    });
  },

  async [UPDATE_BANKACCOUNT](context, payload) {
    PayoutService.init();
    PayoutService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      PayoutService.patch(`user/${payload.uuid}/bank-account`,payload)
      .then((data) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.errors);
        resolve(response);
      });
    });
  },

  async [GET_BANKACCOUNT_LIST](context) {
    PayoutService.init();
    PayoutService.setHeader();
    const data  = await PayoutService.get(`user/bank-accounts`);
    context.commit(SET_ERROR, '');
    return data;
  },
  
  async [CREATE_PAYOUT_REQUEST](context, payload) {
    PayoutService.init();
    PayoutService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      PayoutService.create(`user/payout/${payload.event_id}/event`,payload)
      .then((data) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.errors);
        resolve(response);
      });
    });
  },

  async [CHANGE_PAYOUTREQUEST_STATUS](context, payload) {
    PayoutService.init();
    PayoutService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      PayoutService.put(`user/${payload.uuid}/payout-transaction?status=${payload.status}`)
      .then((data) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.errors);
        resolve(response);
      });
    });
  },
  async [GET_PAYOUT_REQUEST_LIST](context) {
    PayoutService.init();
    PayoutService.setHeader();
    const data  = await PayoutService.get(`user/payouts`);
    context.commit(SET_ERROR, '');
    return data;
  },
  async [EXPORT_PAYOUT_REQUEST_REPORT](context) {
    PayoutService.init();
    PayoutService.setHeader();
    const url  = await PayoutService.get(`user/payouts/export`);
    context.commit(SET_ERROR, '');
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'file.xlsx'); //or any other extension
    link.setAttribute('target', '_blank'); //or any other extension
    document.body.appendChild(link);
    link.click();
    return;
  },
};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
};

export default {
  state,
  getters,
  actions,
  mutations
};
