import Vue from "vue"
var moment = require('moment-timezone');
Vue.filter("checkDateTimePassed", function(date, time, timezone){
  var tz = moment.tz.guess() || Intl.DateTimeFormat().resolvedOptions().timeZone;
  
  date = date.split(" ");
  
  const convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(' ');
  
    let [hours, minutes] = time.split(':');
  
    if (hours === '12') {
      hours = '00';
    }
  
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }
  
    return `${hours}:${minutes}`;
  }

  const isDatetimePassedInTimezones = (datetime, timezones) => {
    const now = moment();
    const targetDatetime = moment(datetime);
    
    for (const timezone of timezones) {
      const timezoneNow = now.tz(timezone);
      if (timezoneNow.isAfter(targetDatetime)) {
        return true;
      } else {
        return false;
      }
    }
  }
  
  const cdate = date[0]+" "+moment(convertTime12to24(time), "h:mm:ss").format("HH:mm:ss");
  
  const datetimeToCheck = cdate; //'2023-08-11 17:00:00'; 
  const timezonesToCheck = [timezone, tz];

  return isDatetimePassedInTimezones(datetimeToCheck, timezonesToCheck);
  
});
Vue.filter("getTimeByTimezone", function(time, date, timezone, type='time'){

  var tz = moment.tz.guess() || Intl.DateTimeFormat().resolvedOptions().timeZone;

  const convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(' ');
  
    let [hours, minutes] = time.split(':');
  
    if (hours === '12') {
      hours = '00';
    }
  
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }
  
    return `${hours}:${minutes}`;
  }

  const getTimezoneShort = (timezone) => {
    return moment().tz(timezone).format("z");
  };

  const cdate = date+"T"+moment(convertTime12to24(time), "h:mm:ss").format("HH:mm:ss");
  const utcDate = moment.tz(cdate, timezone).utc().format();
  if(type == 'date'){
    return moment(utcDate).local().format('ddd, DD MMM YYYY')
  }else{
    return `${moment(utcDate).local().format('h:mm A')} ${getTimezoneShort(tz)}`
    // return moment.tz(utcDate,timezone).format('h:mm A z')
  }
})

Vue.filter("getEventTime", function(time, date, timezone, type='time'){

  var tz = moment.tz.guess() || Intl.DateTimeFormat().resolvedOptions().timeZone;

  const convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(' ');
  
    let [hours, minutes] = time.split(':');
  
    if (hours === '12') {
      hours = '00';
    }
  
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }
  
    return `${hours}:${minutes}`;
  }

  const getTimezoneShort = (timezone) => {
    return moment().tz(timezone).format("z");
  };

  const cdate = date+"T"+moment(convertTime12to24(time), "h:mm:ss").format("HH:mm:ss");
  const utcDate = moment.tz(cdate, timezone).utc().format();
  if(type == 'date'){
    return moment(utcDate).local().format('ddd, DD MMM YYYY')
  }else if(type == 'AMPM'){
    return `${moment(utcDate).local().format('a')}`
  }else if(type == 'timezone'){
    return `${getTimezoneShort(tz)}`
  } else{
    return `${moment(utcDate).local().format('h:mm')}`
  }
})

Vue.filter("changeDateYYYYMMMDD", function(value){
  return moment(value, 'DD MMM YYYY').format('YYYY-MM-DD');
});

Vue.filter("changeDateDDMMMYYYY", function(value){
  return moment(value, 'YYYY-MM-DD').format('DD MMM YYYY');
});

Vue.filter("changeDateYYYYMMDD", function(value){
  return moment(value, 'DD MMM YYYY').format('YYYY-MM-DD');
});

Vue.filter("changeDateDDMMYYYY", function(value){
  return moment(value, 'YYYY-MM-DD').format('DD-MM-YYYY');
});

Vue.filter("changeDateMMDDYYYY", function(value){
  return moment(value, 'YYYY-MM-DD').format('MM-DD-YYYY');
});

Vue.filter("getDateTimeByTimezoneIframe", function(time, date, timezone){

  var tz = moment.tz.guess() || Intl.DateTimeFormat().resolvedOptions().timeZone;

  const convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(' ');
  
    let [hours, minutes] = time.split(':');
  
    if (hours === '12') {
      hours = '00';
    }
  
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }
  
    return `${hours}:${minutes}`;
  }

  const getTimezoneShort = (timezone) => {
    return moment().tz(timezone).format("z");
  };

  const cdate = date+"T"+moment(convertTime12to24(time), "h:mm:ss").format("HH:mm:ss");
  const utcDate = moment.tz(cdate, timezone).utc().format();
  return `${moment(utcDate).local().format('YYYY-MM-DD h:mm A')} ${getTimezoneShort(tz)}`
})

Vue.filter("changeTimeA", function(value){
  return moment(value, 'hh:mm a').format('h:mm A');
});

Vue.filter("getImageWithBasePath", function(image){
  return { 'background-image': `url(${process.env.VUE_APP_BASE}${image})` }
});