import {
  EventService,
} from "@/common/api.service";

import {
  CREATE_PROMOCODE,
  FETCH_PROMOCODE,
  REMOVE_PROMOCODE,
  EDIT_PROMOCODE,
  UPDATE_PROMOCODE
} from "./actions.type";
//SET_FETCH_PROMOCODE
import { ISLOADING, SET_ERROR} from "./mutations.type";

const state = {
  events: {},
  errors: null,
  promocodeData: {}
};

const getters = {
  promocodeData(state){
    return state.promocodeData;
  }
};

const actions = {
  async [CREATE_PROMOCODE](context, payload) {
    EventService.init();
    EventService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      EventService.create(`events/${payload.event_uuid}/promocodes`, payload.params)
      .then(({ data }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.errors);
        resolve(response);
      });
    });
  },
  async [FETCH_PROMOCODE](context, payload) {
    EventService.init();
    context.commit(ISLOADING, true);
    const { data } = await EventService.get(`events/${payload.uuid}/promocodes/all`, payload.query);
    context.commit(ISLOADING, false);
    //context.commit(SET_FETCH_PROMOCODE, data.data);
    return data;
  },
  async [REMOVE_PROMOCODE](context, payload) {
    EventService.init();
    EventService.setHeader();
    return new Promise(resolve => {
      EventService.destroy(`events/${payload.event_uuid}/promocodes/${payload.uuid}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        resolve(response);
      });
    });
  },
  [EDIT_PROMOCODE](context, payload) {
    context.commit(EDIT_PROMOCODE, payload);
  },
  async [UPDATE_PROMOCODE](context, payload) {
    EventService.init();
    EventService.setHeader();
    return new Promise(resolve => {
      EventService.patch(`events/${payload.event_uuid}/promocodes/${payload.promocode_uuid}`, payload.params)
      .then(({ data }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.errors);
        resolve(response);
      });
    });
  },
};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [EDIT_PROMOCODE](state, data) {
    state.promocodeData = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
