import { CampaingService, EventService } from "@/common/api.service";

import {
  CREATE_CAMPAIGN,
  FETCH_CAMPAIGN,
  FETCH_CAMPAIGNS,
  CREATE_CAMPAIGN_LOCATION,
  ADD_FAQ,
  ADD_STORY,
  ADD_PRODUCT_STAGE,
  UPDATE_PRODUCT_STAGE,
  DOCUMENT_UPLOAD,
  FETCH_DEVELOPMENT_DOCUMENT,
  ADD_PRIMARY_CONTACT,
  FETCH_CAMPAIGN_CONTACT,
  UPDATE_PRIMARY_CONTACT,
  ADD_TEAM_MEMBER,
  FETCH_TEAMMEMBERS,
  UPDATE_TEAM_MEMBER,
  FETCH_CAMPAIGN_DETAILS,
  UPDATE_CAMPAIGN,
  UPDATE_CAMPAIGN_LOCATION,
  UPDATE_FAQ,
  DETELE_FAQ,
  UPDATE_STORY,
  REMOVE_DOCUMENT,
  ADD_MATRIX_STAGE,
  DELETE_MATRIX,
  CAMPAIGN_DONATE,
  GET_DONATIONS,
  GET_DONATE_DETAILS,
  ADD_CAMPAIGN_CATEGORY,
  GET_CAMPAIGN_CATEGORY,
  UPDATE_CAMPAIGN_CATEGORY,
  DONATE_DETAILS,
  CAMPAIGN_ORDERTRANSACTION,
  CAMPAIGN_COMMON_ACTION,
  GET_PERTICULAR_CAMPAIGN_DONATION,
  FETCH_ORGNAME,
  DELETE_CAMPAIGN,
  DONATION_REPORT
} from "./actions.type";
import { ISLOADING, SET_ERROR, SET_CAMPAIGN_DETAILS, ISDONATE } from "./mutations.type";

const state = {
  campaign: {},
  errors: null,
  campaignDetails: null,
  donateDetail: null,
};

const getters = {
  campaign(state) {
    return state.campaign;
  },
  campaignDetails(state){
    return state.campaignDetails;
  },
  donateDetail(state){
    return state.donateDetail;
  },
  
};

const actions = {

  async [FETCH_ORGNAME](context, payload) {
    CampaingService.initBase();
    CampaingService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      CampaingService.create(`orgData`, payload)
      .then(({ data }) => {
        resolve(data);
        context.commit(ISLOADING, false);
      })
      .catch(({ response }) => {
        resolve(response);
        context.commit(ISLOADING, false);
      });
    });
  },

  async [CREATE_CAMPAIGN](context, payload) {
    CampaingService.init();
    CampaingService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      CampaingService.create(`campaign/campaign/`, payload)
      .then(({ data }) => {
        console.log("response >", data)
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        console.log("response catch >",response)
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.detail);
        resolve(response);
      });
    });
  },

  async [UPDATE_CAMPAIGN](context, payload) {
    CampaingService.init();
    CampaingService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      CampaingService.patch(`campaign/campaign/`, payload)
      .then(({ data }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.detail);
        resolve(response);
      });
    });
  },

  async [CREATE_CAMPAIGN_LOCATION](context, payload) {
    CampaingService.init();
    CampaingService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      CampaingService.create(`campaign/location/`, payload)
      .then(({ data }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.detail);
        resolve(response);
      });
    });
  },

  
  async [UPDATE_CAMPAIGN_LOCATION](context, payload) {
    CampaingService.init();
    CampaingService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      CampaingService.patch(`campaign/location/`, payload)
      .then(({ data }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.detail);
        resolve(response);
      });
    });
  },

  async [ADD_FAQ](context, payload) {
    CampaingService.init();
    CampaingService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      CampaingService.create(`campaign/faq/`, payload)
      .then(({ data }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.detail);
        resolve(response);
      });
    });
  },
  
  async [UPDATE_FAQ](context, payload) {
    CampaingService.init();
    CampaingService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      CampaingService.patch(`campaign/faq/`, payload)
      .then(({ data }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.detail);
        resolve(response);
      });
    });
  },

  
  async [DETELE_FAQ](context, payload) {
    CampaingService.init();
    CampaingService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      CampaingService.destroy(`campaign/faq/`, payload)
      .then(({ data }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.detail);
        resolve(response);
      });
    });
  },

  async [DELETE_CAMPAIGN](context, payload) {
    CampaingService.init();
    CampaingService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      CampaingService.destroy(`campaign/campaign/${payload}/delete/`)
      .then(( data ) => {
        context.commit(ISLOADING, false);
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        resolve(response);
      });
    });
  },

  async [ADD_STORY](context, payload) {
    CampaingService.init();
    CampaingService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      CampaingService.create(`campaign/story/`, payload)
      .then(({ data }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.detail);
        resolve(response);
      });
    });
  },
  async [UPDATE_STORY](context, payload) {
    CampaingService.init();
    CampaingService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      CampaingService.patch(`campaign/story/`, payload)
      .then(({ data }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.detail);
        resolve(response);
      });
    });
  },

  async [ADD_MATRIX_STAGE](context, payload) {
    CampaingService.init();
    CampaingService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      CampaingService.create(`campaign/add-new-matrix/`, payload)
      .then(({ data }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.detail);
        resolve(response);
      });
    });
  },
  
  async [DELETE_MATRIX](context, payload) {
    CampaingService.init();
    CampaingService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      CampaingService.destroy(`campaign/remove-matrix/`, payload)
      .then(({ data }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.detail);
        resolve(response);
      });
    });
  },

  async [ADD_PRODUCT_STAGE](context, payload) {
    CampaingService.init();
    CampaingService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      CampaingService.create(`campaign/product-stage/`, payload)
      .then(({ data }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.detail);
        resolve(response);
      });
    });
  },

  async [UPDATE_PRODUCT_STAGE](context, payload) {
    CampaingService.init();
    CampaingService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      CampaingService.patch(`campaign/product-stage/`, payload)
      .then(({ data }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.detail);
        resolve(response);
      });
    });
  },
  
  async [DOCUMENT_UPLOAD](context, payload) {
    CampaingService.init();
    CampaingService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      CampaingService.create(`campaign/documents-upload/`, payload)
      .then(({ data }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.detail);
        resolve(response);
      });
    });
  },

  async [REMOVE_DOCUMENT](context, payload) {
    CampaingService.init();
    CampaingService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      CampaingService.destroy(`campaign/documents-upload/`, payload)
      .then(({ data }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.detail);
        resolve(response);
      });
    });
  },

  async [FETCH_CAMPAIGN](context, slug) {
    context.commit(ISLOADING, true);
    CampaingService.init();
    CampaingService.setHeader();
    const { data } = await CampaingService.get(`campaign/publish-campaigns/${slug}`);
    context.commit(ISLOADING, false);
    return data;
  },

  //For front-end campaign list
  async [FETCH_CAMPAIGNS](context, slug) {
    context.commit(ISLOADING, true);
    CampaingService.init();
    CampaingService.setHeader();
    return new Promise(resolve => {
      CampaingService.get(`campaign/campaigns/${slug}`)
      .then(({ data }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response);
        resolve(response);
      });
    });

  },
  
  async [FETCH_DEVELOPMENT_DOCUMENT](context,queryprm) {
    CampaingService.init();
    CampaingService.setHeader();
    const { data } = await CampaingService.get(`campaign/documents-upload/`,queryprm);
    return data;
  },

  async [ADD_PRIMARY_CONTACT](context, payload) {
    CampaingService.init();
    CampaingService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      CampaingService.create(`campaign/primary-contact/`, payload)
      .then(({ data }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.detail);
        resolve(response);
      });
    });
  },
  
  async [FETCH_CAMPAIGN_CONTACT](context, payload) {
    CampaingService.init();
    CampaingService.setHeader();
    const { data } = await CampaingService.get(`campaign/primary-contact/`, payload);
    return data;
  },

  
  async [UPDATE_PRIMARY_CONTACT](context, payload) {
    CampaingService.init();
    CampaingService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      CampaingService.patch(`campaign/primary-contact/`, payload)
      .then(({ data }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.detail);
        resolve(response);
      });
    });
  },

  async [ADD_TEAM_MEMBER](context, payload) {
    CampaingService.init();
    CampaingService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      CampaingService.create(`campaign/team-member/`, payload)
      .then(({ data }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.detail);
        resolve(response);
      });
    });
  },

  async [FETCH_TEAMMEMBERS](context, payload) {
    CampaingService.init();
    CampaingService.setHeader();
    const { data } = await CampaingService.get(`campaign/team-member/`, payload);
    return data;
  },
  
  async [UPDATE_TEAM_MEMBER](context, payload) {
    CampaingService.init();
    CampaingService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      CampaingService.patch(`campaign/team-member/`, payload)
      .then(({ data }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.detail);
        resolve(response);
      });
    });
  },

  async [FETCH_CAMPAIGN_DETAILS](context, payload) {
    CampaingService.init();
    CampaingService.setHeader();
    context.commit(ISLOADING, true);
    const { data } = await CampaingService.get(`campaign/campaign-all-details-by-slug/`, payload); //campaign-all-details
    context.commit(SET_CAMPAIGN_DETAILS, data.data[0]);
    context.commit(ISLOADING, false);
    return data;
  },

  async [CAMPAIGN_DONATE](context, payload) {
    CampaingService.init();
    // if(payload && payload.user_id){
    //   CampaingService.setHeader(payload.user_id);  
    //   delete payload.user_id;
    // }else{
    //   CampaingService.setHeader();
    // }
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      CampaingService.create(`campaign/donation/`, payload)
      .then(({ data }) => {
        //context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.detail);
        resolve(response);
      });
    });
  },
  
  async [GET_DONATIONS](context, payload) {
    CampaingService.init();
    CampaingService.setHeader();
    context.commit(ISLOADING, true);
    var query = `?offset=${payload.offset}&limit=${payload.limit}`;
    const { data } = await CampaingService.get(`campaign/donation/`, query);
    context.commit(ISLOADING, false);
    return data;
  },

  async [GET_DONATE_DETAILS](context, payload) {
    CampaingService.init();
    CampaingService.setHeader();
    context.commit(ISLOADING, true);
    console.log("payload >> ",payload);
    const { data } = await CampaingService.get(`campaign/get-donation/?donation_id=${payload}`);
    context.commit(ISLOADING, false);
    return data;
  },

  async [ADD_CAMPAIGN_CATEGORY](context, payload) {
    CampaingService.init();
    CampaingService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      CampaingService.create(`campaign/categories/`, payload)
      .then(({ data }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.detail);
        resolve(response);
      });
    });
  },

  async [GET_CAMPAIGN_CATEGORY](context, payload) {
    CampaingService.init();
    CampaingService.setHeader();
    context.commit(ISLOADING, true);
    const { data } = await CampaingService.get(`campaign/categories/?campaign_id=${payload}`);
    context.commit(ISLOADING, false);
    return data;
  },

  async [UPDATE_CAMPAIGN_CATEGORY](context, payload) {
    CampaingService.init();
    CampaingService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      CampaingService.patch(`campaign/categories/`, payload)
      .then(({ data }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.detail);
        resolve(response);
      });
    });
  },
  
  async [DONATE_DETAILS](context, data) {
    context.commit(ISDONATE, data);
    //donateDetail
  },

  async [CAMPAIGN_ORDERTRANSACTION](context, payload) {
    CampaingService.init();
    return new Promise(resolve => {
      CampaingService.patch(`campaign/payment-status/`, payload)
      .then(({ data }) => {
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.errors);
        resolve(response);
      });
    });
  },

  async [CAMPAIGN_COMMON_ACTION](context, payload) {
    context.commit(ISLOADING, true);
    EventService.init();
    if(payload.isDonation){
      payload = payload.formdata
      EventService.setHeader();
    }
    return new Promise(resolve => {
      EventService.create(`events/campaign`, payload)
      .then(({ data }) => {
        context.commit(SET_ERROR, '');
        context.commit(ISLOADING, false);
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.errors);
        context.commit(ISLOADING, false);
        resolve(response);
      });
    });
  },

  async [GET_PERTICULAR_CAMPAIGN_DONATION](context, payload) {
    CampaingService.init();
    CampaingService.setHeader();
    context.commit(ISLOADING, true);
    const { data } = await CampaingService.get(`campaign/get-donation-by-id/${payload}`);
    context.commit(ISLOADING, false);
    return data;
  },

  //Donation Report Api
  async [DONATION_REPORT](context,payload) {
    CampaingService.init();
    CampaingService.setHeader();
    context.commit(ISLOADING, true);
    var query = ''
    if(payload){
      query = payload.filter;
      if (payload.campaign_name) {
        query = query + '&campaignname=' + payload.campaign_name;
      }

      if (payload.search) {
        query = query + `&search=${payload.search && payload.search !== null ? payload.search : null}`;
      }
      if (payload.transaction_id) {
        query = query + `&transaction_id=${payload.transaction_id && payload.transaction_id !== null ? payload.transaction_id : null}`;
      }
    }
    const data = await CampaingService.get(`campaign/donation-list/${payload.user_id}${query}`);
    context.commit(ISLOADING, false);
    return data;
  },

};

const mutations = {
  [ISDONATE](state, detail) {
    state.donateDetail = detail;
  },
  [ISLOADING](state, loading) {
    state.isLoading = loading;
  },
  [SET_CAMPAIGN_DETAILS](state, data) {
    state.campaignDetails = data;
  },
  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
