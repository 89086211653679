import {
  PayoutService,
} from "@/common/api.service";

import {
  UPDATE_PAYOUT_REQUEST
} from "./actions.type";
//SET_FETCH_TICKETS
import { ISLOADING, SET_ERROR } from "./mutations.type";

const state = {
  errors: null,
  ticketData: {}
};

const getters = {
};

const actions = {
  async [UPDATE_PAYOUT_REQUEST](context, payload) {
    PayoutService.init();
    PayoutService.setHeader();
    context.commit(ISLOADING, true);
    const { data } = await PayoutService.get(`payouts/${payload}`);
    context.commit(ISLOADING, false);
    return data;
  },
};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  }, 
};

export default {
  state,
  getters,
  actions,
  mutations
};
