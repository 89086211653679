//define state
const state = {
  sliderOptions: {
    percentageValue: 0, //1
    min: 0,
    max: 0, //5
  },

  buttonList: [
    {
      class: "btn btn-lg mb-3",
      amount: 1000,
      active: false,
    },
    {
      class: "btn btn-lg mb-3",
      amount: 2000,
      active: false,
    },
    {
      class: "btn btn-lg mb-3",
      amount: 3000,
      active: false,
    },
    {
      class: "btn btn-lg mb-3",
      amount: 4000,
      active: false,
    },
  ],

  amountOptions: {
    customAmount: null,
  },

  tipOptions: {
    customtipAmount: "",
    showCustomTipTextBox: false,
  },

  amountTotal: 0,

  isOpen: false,
};

//get methods
const getters = {
  getSliderOptions: (state) => state.sliderOptions,
  getButtonList: (state) => state.buttonList,
  getAmountOptions: (state) => state.amountOptions,
  getTipOptions: (state) => state.tipOptions,
  getAmountTotal: (state) => state.amountTotal,
};

//used for update getters value
const mutations = {
  setSliderOptions(state, newdata) {
    state.sliderOptions = newdata;
  },

  setButtonList(state, newdata) {
    state.buttonList = newdata;
  },

  setAmountOptions(state, newdata) {
    state.amountOptions = newdata;
  },

  setTipOptions(state, newdata) {
    state.tipOptions = newdata;
  },

  setAmountTotal(state, newdata) {
    state.amountTotal = newdata;
  },
  openModal(state, isOpen) {
    state.isOpen = isOpen;
  },
};

export default {
  state,
  getters,
  mutations,
};
