export default {
    methods: {
        showInfo: function (msg, type) {
            this.$toast.open({
                message: msg,
                type: type,
                duration: 10000,
                position: "top-right",
                dismissible: true,
            });
        },
        convertCurrencyFormat: function(currencyAmount) {
            //return (Math.round(currencyAmount * 100) / 100).toLocaleString('en-IN');
            return this.formatNumbers((Math.round(currencyAmount * 100) / 100).toFixed(2));
        },
        formatNumbers(origionalNumber) {
            return origionalNumber.toString().replace(/[^-\d.]/g, "")
            // Remove duplicated decimal point, if one exists:
            .replace(/^(\d*\.)(.*)\.(.*)$/, '$1$2$3')
            // Keep only two digits past the decimal point:
            .replace(/\.(\d{2})\d+/, '.$1')
            // Add thousands separators:
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        returnPerfromTargetAndRaised(raisedRaised, targetAmount){
            if(raisedRaised > 0 && targetAmount > 0){
                let per = raisedRaised * 100 / targetAmount;
                if(per >= 100){
                    return 100
                }
                return (Math.round(per * 100) / 100).toFixed(2);
            }
            return 0;
        }
    },
  }