import {
  EventService,
} from "@/common/api.service";

import {
  CREATE_TICKET,
  FETCH_TICKETS,
  REMOVE_TICKET,
  EDIT_TICKET_DATA,
  RESEND_TICKET,
  UPDATE_TICKET,
  CHECK_VALIDATE_PROMOCODE,
  GET_ORDERS,
  GET_ORDERS_DETAILS,
  SET_PURCHASE_DETAILS,
  PURCHASE_SOLDOUT,
  TICKET_SHORT,
  TOGGLE_IS_HIDDEN
} from "./actions.type";
//SET_FETCH_TICKETS
import { ISLOADING, SET_ERROR, EDIT_TICKET, ISBUTTONLOADING, PURCHASE_DETAILS, SOLD_OUT } from "./mutations.type";

const state = {
  events: {},
  errors: null,
  ticketData: {},
  purchasedTickets: {},
  soldOutTickets: {}
};

const getters = {
  ticketData(state){
    return state.ticketData;
  },
  purchasedTickets(state){
    return state.purchasedTickets;
  },
  soldOutTickets(state){
    return state.soldOutTickets;
  }
};

const actions = {
  async [CREATE_TICKET](context, payload) {
    EventService.init();
    EventService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      EventService.create(`events/${payload.event_uuid}/tickets`, payload.params)
      .then(({ data }) => {
        console.log("in create tickets",data.data);
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.errors);
        resolve(response);
      });
    });
  },
  async [TOGGLE_IS_HIDDEN](context, payload) {
    EventService.init();
    EventService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      EventService.patch(`events/tickets/${payload.uuid}/${payload.is_hidden}`)
      .then(({ data }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.errors);
        resolve(response);
      });
    });
  },
  async [FETCH_TICKETS](context, payload) {
    EventService.init();
    context.commit(ISLOADING, true);
    const { data } = await EventService.get(`events/${payload}/tickets`);
    context.commit(ISLOADING, false);
    //context.commit(SET_FETCH_TICKETS, data.data);
    return data.data;
  },
  async [REMOVE_TICKET](context, payload) {
    EventService.init();
    EventService.setHeader();
    return new Promise(resolve => {
      EventService.destroy(`events/${payload.event_uuid}/tickets/${payload.uuid}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((response) => {
        resolve(response);
      });
    });
  },
  [EDIT_TICKET_DATA](context, payload) {
    context.commit(EDIT_TICKET, payload);
  },
  async [RESEND_TICKET](context, payload) {
    EventService.init();
    context.commit(ISLOADING, true);
    const { data } = await EventService.create(`events/sendTicket`, payload);
    context.commit(ISLOADING, false);
    return data.data;
  },
  async [UPDATE_TICKET](context, payload) {
    EventService.init();
    EventService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      EventService.patch(`events/${payload.event_uuid}/tickets/${payload.ticket_uuid}`, payload.params)
      .then(({ data }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.errors);
        resolve(response);
      });
    });
  },
  async [CHECK_VALIDATE_PROMOCODE](context, payload) {
    EventService.init();
    EventService.setHeader();
    context.commit(ISBUTTONLOADING, true);
    return new Promise(resolve => {
      EventService.create(`events/${payload.event_uuid}/promocodes/apply`,payload.data)
      .then(({ data }) => {
        context.commit(ISBUTTONLOADING, false);
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISBUTTONLOADING, false);
        console.log(response);
        resolve(response);
      });
    });
  },
  async [GET_ORDERS](context, payload) {
    
    EventService.init();
    EventService.setHeader();
    context.commit(ISLOADING, true);
    var query = '';
    if(payload){
      query = `?page=${payload.page}&perPage=${payload.perPage}`;  
    }
    const { data } = await EventService.get(`events/orders`, query);
    context.commit(ISLOADING, false);
    return data;
  },
  async [GET_ORDERS_DETAILS](context, payload) {
    EventService.init();
    EventService.setHeader();
    context.commit(ISLOADING, true);
    const { data } = await EventService.get(`events/${payload.event_uuid}/orders/${payload.ticket_uuid}`);
    context.commit(ISLOADING, false);
    return data.data;
  },

  [SET_PURCHASE_DETAILS](context, payload) {
    context.commit(PURCHASE_DETAILS, payload);
  },
  [PURCHASE_SOLDOUT](context, payload) {
    context.commit(SOLD_OUT, payload);
  },
  async [TICKET_SHORT](context, payload) {
    EventService.init();
    EventService.setHeader();
    context.commit(ISLOADING, true);
    return new Promise(resolve => {
      EventService.create(`events/${payload.event_uuid}/sortTickets`, payload.params)
      .then(({ data }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, '');
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(ISLOADING, false);
        context.commit(SET_ERROR, response.data.errors);
        resolve(response);
      });
    });
  },
};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
  [SOLD_OUT](state, error) {
    state.soldOutTickets = error;
  },
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [EDIT_TICKET](state, data) {
    state.ticketData = data;
  },
  [PURCHASE_DETAILS](state, data) {
    state.purchasedTickets = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
