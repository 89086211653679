import { UserManagementService } from "@/common/api.service";  
import { 
    CREATE_USER,
    USER_LIST,
    USER_ROLES,
    ROLE_PERMISSIONS,
    ADD_ROLE,
    ASSIGN_PERMISSION_TO_ROLE,
    UPDATE_USER_ROLE,
    RECENT_USER_LIST
} from "./actions.type";
import { SET_ERROR,ISLOADING} from "./mutations.type";
  
const state = {
};

const getters = {
};

const actions = {
    async [USER_LIST](context) {
        UserManagementService.init();
        UserManagementService.setHeader();
        const data  = await UserManagementService.get(`user/list`);
        context.commit(SET_ERROR, '');
        return data;
    },
    async [CREATE_USER](context, payload) {
      UserManagementService.init();
      UserManagementService.setHeader();
      context.commit(ISLOADING, true);
      return new Promise(resolve => {
        UserManagementService.create(`user/store`, payload)
        .then((data) => {
          context.commit(ISLOADING, false);
          context.commit(SET_ERROR, '');
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(ISLOADING, false);
          context.commit(SET_ERROR, response.data.errors);
          resolve(response);
        });
      });
  },
    async [USER_ROLES](context) {
        UserManagementService.init();
        UserManagementService.setHeader();
        const data  = await UserManagementService.get(`user/roles`);
        context.commit(SET_ERROR, '');
        return data;
    },
    async [ADD_ROLE](context, payload) {
        UserManagementService.init();
        UserManagementService.setHeader();
        context.commit(ISLOADING, true);
        return new Promise(resolve => {
          UserManagementService.create(`user/create-role`, payload)
          .then((data) => {
            context.commit(ISLOADING, false);
            context.commit(SET_ERROR, '');
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(ISLOADING, false);
            context.commit(SET_ERROR, response.data.errors);
            resolve(response);
          });
        });
    },
    async [ROLE_PERMISSIONS](context) {
        UserManagementService.init();
        UserManagementService.setHeader();
        const data  = await UserManagementService.get(`user/permissions`);
        context.commit(SET_ERROR, '');
        return data;
    },
    async [UPDATE_USER_ROLE](context,payload) {
      console.log("payload > > >",payload);
      UserManagementService.init();
      context.commit(ISLOADING, true);
      return new Promise(resolve => {
        UserManagementService.patch(`user/${payload.user_id}/updateRole`, { role_id: payload.role_id })
        .then((data) => {
          context.commit(ISLOADING, false);
          resolve(data);
        })
        .catch((response) => {
          context.commit(ISLOADING, false);
          context.commit(SET_ERROR, response.data.errors);
          resolve(response);
        });
      });
    },
    async [ASSIGN_PERMISSION_TO_ROLE](context,payload) {
        UserManagementService.init();
        UserManagementService.setHeader();
        context.commit(ISLOADING, true);
        return new Promise(resolve => {
          UserManagementService.post(`user/role/${payload.role_id}/assignPermission`, payload.data)
          .then(( data ) => {
            context.commit(ISLOADING, false);
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(ISLOADING, false);
            context.commit(SET_ERROR, response.data.errors);
            resolve(response);
          });
        });
    },
    async [RECENT_USER_LIST](context) {
        UserManagementService.init();
        UserManagementService.setHeader();
        const data  = await UserManagementService.get(`recent/user/list`);
        context.commit(SET_ERROR, '');
        return data;
    },
};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
    
};

export default {
    state,
    getters,
    actions,
    mutations
};